<template>
  <div>
    <v-text-field outlined dense hide-details="auto" label="Körpergewicht in kg" type="number" v-model="data['weight']"
                  @input="change" :error-messages="weightError"></v-text-field>
    <v-text-field outlined dense hide-details="auto" label="Körpergröße in cm" class="mt-3" type="number"
                  v-model="data['height']" @input="change" :error-messages="heightError"></v-text-field>
    <div v-if="bmi">
      <div class="text-h6 grey--text text--darken-3 mt-1">Dein BMI: {{ bmi }}</div>
      <div>
        Möchtest du mehr über deinen BMI und dein Idealgewicht erfahren?
        <a href="https://www.bmi-rechner.net/" target="_blank">Klicke hier!</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BmiCalculatorView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    data() {
      return this.filledOutComp.data;
    },
    bmi() {
      if (this.data["weight"] && this.data["height"]) {
        let result = this.data["weight"] / Math.pow(this.data["height"] / 100.0, 2);
        let rounded = Math.round(result * 10) / 10;
        if (rounded < 10 || rounded > 160) {
          return 0;
        }
        return (rounded + "").replace(".", ",");
      } else {
        return 0;
      }
    },
    weightError() {
      const weight = this.data["weight"];
      if (this.validationResult) {
        if (this.validationResult.status === "EMPTY" && !weight) {
          return "Bitte ausfüllen";
        }
      }
      return "";
    },
    heightError() {
      const height = this.data["height"];
      if (this.validationResult) {
        if (this.validationResult.status === "EMPTY" && !height) {
          return "Bitte ausfüllen";
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>