<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.c_background }" style2="margin-top: 00px; margin-bottom: 0px">

    <v-navigation-drawer v-if="showLinks" v-model="drawer" app temporary>

      <v-list flat>

        <v-list-item link v-if="roleInternalAccess" :to="{ name: 'InternalArea' }">
          <v-list-item-icon>
            <v-icon color="black">mdi-lock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Interner Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <AppNavigationDrawer></AppNavigationDrawer>

    <AppBar></AppBar>

    <router-view :key="$route.fullPath"></router-view>

    <v-snackbar-queue :timeout="20000"></v-snackbar-queue>
    <ConfirmDialog></ConfirmDialog>
  </v-app>
</template>

<script>

import Logo from "@/components/Logo";
import AppBar from "@/components/AppBar";
import AppNavigationDrawer from "@/components/AppNavigationDrawer";
import AppBottomNavigation from "@/components/AppBottomNavigation";
import ConfirmMailHint from "@/components/ConfirmMailHint";

import mitt from "mitt";
import axios from "axios";
import ConfirmDialog from "@/components/reusable/ConfirmDialog.vue";
window.mitt = window.mitt || new mitt();

export default {
  name: "App",
  components: { ConfirmDialog, ConfirmMailHint, AppBottomNavigation, AppNavigationDrawer, AppBar, Logo },
  data: () => ({
    drawer: false
  }),
  computed: {
    showLinks: function() {
      return this.$store.state.userIsLoggedIn;
    },
    roleInternalAccess() {
      return this.$store.state.roles.includes("ROLE_INTERNAL_ACCESS");
    },
    showBottomNavigation() {
      return this.showLinks && this.$vuetify.breakpoint.xsOnly;
    }
  },
  filters: {},
  created() {
    document.title = "Guidance by Roy Spitzner";
    this.$store.commit("setErrorCallback", this.showError)
  },
  mounted() {
    window.mitt.on("acceptTermsRequest", () => {
      this.$router.push("accept-terms")
    });
    window.mitt.on("confirmAccountRequest", () => {
      this.$router.push("confirm-account")
    });
  },
  methods: {
    greet: function() {
      this.hideSizeHelper = true;
    },
    showError(error) {
      this.$snackbar(error, 'error')
    }
  }
};
</script>

<style>
/*@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700');*/

.v-icon--left.fix-button-icon-size {
  font-size: 18px;
}
.transform-none {
  text-transform: none !important;
}

</style>
