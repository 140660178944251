<template>
  <div>

    <div v-if="formComponents">

      <div v-if="showLessonOneHint">
        <div>
          Persönliche Daten gespeichert
          <v-icon small>mdi-check</v-icon>
        </div>
      </div>
      <div v-else>
        <div v-for="(formComponent, index) in formComponents" :key="formComponent.id"
             :class="formComponent['properties']['styleClasses']">

          <TextView v-if="formComponent.type === 1" :component="formComponent" :is-first-item="index === 0"></TextView>

          <RadioButtonsView v-else-if="formComponent.type === 4" :component="formComponent"
                            :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                            :validation-result="validationResults[formComponent.id]"></RadioButtonsView>

          <MultipleChoiceView v-else-if="formComponent.type === 5" :component="formComponent"
                              :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                              :validation-result="validationResults[formComponent.id]"></MultipleChoiceView>

          <TextFieldView v-else-if="formComponent.type === 7" :component="formComponent"
                         :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                         :validation-result="validationResults[formComponent.id]"></TextFieldView>

          <text-field-list-view v-else-if="formComponent.type === 8" :component="formComponent"
                                :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                                :validation-result="validationResults[formComponent.id]"></text-field-list-view>

          <AssignView v-else-if="formComponent.type === 9" :component="formComponent"
                      :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                      :validation-result="validationResults[formComponent.id]"></AssignView>

          <ConfirmationView v-else-if="formComponent.type === 10" :component="formComponent"
                            :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                            :validation-result="validationResults[formComponent.id]"></ConfirmationView>

          <file-upload-view v-else-if="formComponent.type === 11" :component="formComponent"
                            :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                            :validation-result="validationResults[formComponent.id]"></file-upload-view>

          <link-view v-else-if="formComponent.type === 12" :component="formComponent"
                     :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                     :validation-result="validationResults[formComponent.id]"></link-view>

          <file-generation-view v-else-if="formComponent.type === 13" :component="formComponent"
                                :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                                :validation-result="validationResults[formComponent.id]"
                                :form-id="finalFormId" :reference="reference"></file-generation-view>

          <date-picker-view v-else-if="formComponent.type === 14" :component="formComponent"
                            :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                            :validation-result="validationResults[formComponent.id]"></date-picker-view>

          <choice-box-view v-else-if="formComponent.type === 15" :component="formComponent"
                           :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                           :validation-result="validationResults[formComponent.id]"></choice-box-view>

          <bmi-calculator-view v-else-if="formComponent.type === 16" :component="formComponent"
                               :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                               :validation-result="validationResults[formComponent.id]"></bmi-calculator-view>

          <financial-goals-view v-else-if="formComponent.type === 17" :component="formComponent"
                                :filled-out-comp="filledOutFormComponents[formComponent.id]" :on-change="change"
                                :validation-result="validationResults[formComponent.id]"></financial-goals-view>

          <financial-goals-priority-view v-else-if="formComponent.type === 18" :component="formComponent"
                                         :filled-out-comp="filledOutFormComponents[formComponent.id]"
                                         :on-change="change" :validation-result="validationResults[formComponent.id]"
                                         :reference="reference"></financial-goals-priority-view>

        </div>
      </div>

      <div v-if="showValidationResult" class="d-flex justify-center align-center mt-4">
        <div v-if="valid" class="success--text text-h6">
          Alles erledigt - Super!
        </div>
        <div v-else class="error--text text-h6">
          Bitte überprüfe deine Angaben
        </div>
      </div>

      <v-row class="justify-end mt-4" :class2="{'mt-4': !showValidationResult}" dense>
        <v-col class="col-12 col-sm flex-grow-0">
          <v-btn color="primary" :outlined="valid" @click="validate" :loading="validationInProgress"
                 style="width: 100%" :disabled="showLessonOneHint">
            Antworten bestätigen
          </v-btn>
        </v-col>
        <v-col v-if="advanceButtonText" class="col-12 col-sm flex-grow-0">
          <v-btn color="primary" :outlined="!valid" style="width: 100%" :loading="advanceButtonLoading"
                 :disabled="!valid && disableAdvanceButtonIfNotPrimaryTarget" @click="advance">
            {{ advanceButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-else-if="noData">
      <div>{{ altText }}</div>
      <v-row v-if="advanceButtonText" class="justify-end mt-3" dense>
        <v-col class="col-12 col-sm flex-grow-0">
          <v-btn color="primary" style="width: 100%" :loading="advanceButtonLoading" @click="advance">
            {{ advanceButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <em>Inhalte werden geladen...</em>
    </div>

  </div>
</template>

<script>
import TextView from "@/components/form_components_view/TextView";
import RadioButtonsView from "@/components/form_components_view/RadioButtonsView";
import Vue from "vue";
import _ from "lodash";
import uniqueString from "unique-string";
import TextFieldView from "@/components/form_components_view/TextFieldView";
import AssignView from "@/components/form_components_view/AssignView";
import MultipleChoiceView from "@/components/form_components_view/MultipleChoiceView";
import ConfirmationView from "@/components/form_components_view/ConfirmationView";
import mitt from "mitt";
import FileUploadView from "@/components/form_components_view/FileUploadView";
import LinkView from "@/components/form_components_view/LinkView";
import FileGenerationView from "@/components/form_components_view/FileGenerationView";
import TextFieldListView from "@/components/form_components_view/TextFieldListView";
import DatePickerView from "@/components/form_components_view/DatePickerView";
import ChoiceBoxView from "@/components/form_components_view/ChoiceBoxView";
import BmiCalculatorView from "@/components/form_components_view/BmiCalculatorView";
import FinancialGoalsView from "@/components/form_components_view/FinancialGoalsView";
import FinancialGoalsPriorityView from "@/components/form_components_view/FinancialGoalsPriorityView";

window.mitt = window.mitt || new mitt();

export default {
  name: "Form",
  components: {
    FinancialGoalsPriorityView,
    FinancialGoalsView,
    BmiCalculatorView,
    ChoiceBoxView,
    DatePickerView,
    TextFieldListView,
    FileGenerationView,
    LinkView,
    FileUploadView,
    ConfirmationView,
    MultipleChoiceView,
    AssignView,
    TextFieldView,
    RadioButtonsView,
    TextView
  },
  props: {
    formId: {
      type: Number
    },
    lessonId: {
      type: Number
    },
    reference: {
      type: String,
      required: true
    },
    altText: {
      type: String
    },
    validStatusCallback: {
      type: Function
    },
    advanceButtonText: {
      type: String
    },
    advanceButtonLoading: {
      type: Boolean
    },
    disableAdvanceButtonIfNotPrimaryTarget: {
      type: Boolean
    },
    advanceCallback: {
      type: Function
    }
  },
  data() {
    return {
      finalFormId: -1,
      form: null,
      formComponents: null,
      filledOutFormComponents: null,
      filledOutFormCompsToPush: {},
      noData: false,
      valid: false,
      validationInProgress: false,
      validationResults: {},
      showValidationResult: false
    };
  },
  computed: {
    showLessonOneHint() {
      if (this.form) {
        const lockIfValid = this.form["lockIfValid"];
        return this.valid && lockIfValid;
      }
      return false;
    }
  },
  mounted() {
    window.mitt.on("showFormValidationResult", validationResult => {
      this.validateCallback(validationResult);
    });
    this.fetchFormData();
  },
  methods: {
    fetchFormData() {
      if (this.formId) {
        this.$store.dispatch("getRequest", ["forms/" + this.formId + "/" + this.reference, this.fetchFormDataCallback]);
      } else if (this.lessonId) {
        this.$store.dispatch("getRequest", ["forms/by-lesson/" + this.lessonId + "/" + this.reference, this.fetchFormDataCallback]);
      }
    },
    fetchFormDataCallback(data) {
      if (data) {
        this.finalFormId = data.form.id;
        this.form = data["form"];
        let formComponents = data["formComponents"];
        formComponents.sort((a, b) => a.position - b.position);
        this.formComponents = formComponents;
        this.filledOutFormComponents = data["filledOutFormComponents"];
        const formValidationResult = data["formValidationResult"];
        if (formValidationResult !== null) {
          this.valid = formValidationResult.valid;
        } else {
          this.valid = false;
        }
      } else {
        this.valid = true;
        this.noData = true;
      }
      this.notifyFormStatus();
    },
    change(filledOutComp) {
      Vue.set(this.filledOutFormCompsToPush, filledOutComp["formComponentId"], filledOutComp);
      this.showValidationResult = false;
      if (this.valid) {
        this.valid = false;
        this.notifyFormStatus();
        this.$store.dispatch("putRequest", ["forms/" + this.finalFormId + "/" + this.reference + "/set-invalid", {}]);
      }
      this.schedulePush();
    },
    schedulePush: _.debounce(function() {
      this.pushChanges();
    }, 300),
    pushChanges() {
      let updates = Object.values(this.filledOutFormCompsToPush);
      this.filledOutFormCompsToPush = {};
      /*
      let identifier = uniqueString();
      let requestPipelineEntry = {
        identifier: identifier,
        data: components,
        status: "pending"
      };
      Vue.set(this.pendingRequests, identifier, requestPipelineEntry);
      */
      this.$store.dispatch("putRequest", ["forms/fill-out", updates]);
    },
    validate() {
      this.validationInProgress = true;
      this.$store.dispatch("putRequest", ["forms/" + this.finalFormId + "/" + this.reference + "/validate", {}, this.validateCallback]);
    },
    validateCallback(data) {
      this.validationInProgress = false;
      this.valid = data.valid;
      this.validationResults = data["componentResults"];
      this.showValidationResult = true;
      this.notifyFormStatus();
    },
    notifyFormStatus() {
      if (this.validStatusCallback) {
        this.validStatusCallback(this.valid);
      }
    },
    advance() {
      if (this.advanceCallback) {
        this.advanceCallback();
      }
    }
  }
};
</script>

<style scoped>

</style>