<template>
  <div class="signup-buttons">
    <v-btn color="blue" @click="socialLogin">Google</v-btn>
  </div>
</template>

<script>
import firebase from "firebase/compat";

export default {
  name: "SocialLoginOutdated",
  mounted () {

  },
  methods: {
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();

      firebase.auth().signInWithPopup(provider).then(value => {
        console.log(value);
      })
    }
  }
};
</script>

<style scoped>

</style>