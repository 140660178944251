<template>
  <v-card tile flat class="" :color="$vuetify.theme.themes.light.c_bg_landingpage">
    <v-card-text class="text-center black--text">
      <h1 class="d-sm-none text-h6 font-weight-regular" v-text="text"></h1>
      <h1 class="d-none d-sm-block text-h4 font-weight-light" v-text="text"></h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Headline",
  props: {
    text: String,
  },
};
</script>

<style scoped>

.handwriting {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-weight: 500;
}

.text-h6 {
  font-size: 15pt !important;
  line-height: 1.3;
}

.text-h4 {
  line-height: 1.4;
}

</style>