<template>
  <div class="d-flex justify-center align-center custom-social-login-container custom-social-login-container-disabled" @click="login">
    <div class="custom-social-login-logo d-flex">
      <v-img src="@/assets/images/social_login/fb2.svg" max-width="22" contain></v-img>
    </div>
    <div v-if="!loginInProgress" class="custom-social-login-text">Facebook</div>
    <div v-else class="d-flex justify-center flex-grow-1">
      <v-progress-circular indeterminate color="white" size="25" width="3"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";

export default {
  name: "SocialLoginFacebookButton",
  data() {
    return {
      loginInProgress: false
    };
  },
  methods: {
    login()  {
      return;
      this.loginInProgress = true;

      const provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithPopup(provider).then(firebaseResponse => {
        console.log(firebaseResponse);
        this.$store.dispatch("processFirebaseResponse", [firebaseResponse, this.loginCallback]);
      }).catch(() => {
        this.loginInProgress = false;
        this.$store.dispatch("showError", "Anmeldung fehlgeschlagen oder abgebrochen");
      });
    },
    loginCallback(data) {
      this.loginInProgress = false;
      if (data.success) {
        let routeAfterLogin = this.$store.state.routeAfterLogin;
        if (routeAfterLogin === "") {
          this.$router.push("/home");
        } else {
          this.$router.push(routeAfterLogin);
        }
      }
    }
  }
};
</script>

<style scoped>
.custom-social-login-container {
  height: 42px;
  background-color: #4267b2;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  border-radius: 2px;
  cursor: pointer;
}

.custom-social-login-container-disabled {
  background-color: #cfd0d9;
  box-shadow: none;
  cursor: default;
  user-select: none;
}

.custom-social-login-logo {
  padding-left: 10px;
}

.custom-social-login-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 42px;
  font-weight: bold;
  letter-spacing: .25px;
  color: white;
  flex-grow: 1;
  text-align: center;
}

.custom-social-login-disabled {
  pointer-events: none;
  opacity: 0.25;
}
</style>