<template>
  <v-card>
    <v-img
        class="black--text align-center header-image"
        aspect-ratio="5"
        src="@/assets/images/climber_1080.jpg"
    >
      <div class="">
        <div class="text-h4 mx-4 mt-4 text-sm-h4 mt-sm-8 text-md-h3 mt-md-12 mb-md-8 header-title" style="text-align: center;">Karriereplanung ist Lebensplanung</div>
        <div class="text-body-1 mx-4 mb-4 mt-3 text-sm-h6 mx-sm-8 mb-sm-8 text-md-h5 mb-md-12 mx-lg-16 header-text" style="text-align: center;">
          Mit den folgenden Kursen wollen wir nicht nur deine Karriere sondern auch dein Leben voranbringen.
        </div>
      </div>

    </v-img>

  </v-card>
</template>

<script>
export default {
  name: "MainImageHeader"
};
</script>

<style>
.header-image .v-image__image {
  filter: saturate(80%) contrast(50%) brightness(150%);
}
</style>

<style scoped>
div.text-h3, div.text-h4 {
  /*font-family: "Caveat", sans-serif !important;*/
  font-weight: 300;
}
div.text-h5, div.text-body-1 {
  /*font-family: "Caveat", sans-serif !important;*/
  font-weight: 400;
}

@media (max-width: 599px) {
  .header-text.text-body-1 {
    line-height: 1.3;
    font-weight: 400;
  }
}




</style>