<template>
<div>
  <a href="https://outlook.office365.com/owa/calendar/mitNORMGmbH9@mitNORM.com/bookings/" target="_blank">
    <v-row dense>
      <v-col v-for="image in images" :key="image['fileName']" class="col-4 col-sm-4 col-md-2" style2="max-width: 80px">
        <CurrentEvent :image-name="image['fileName']" :title="image['title']"></CurrentEvent>
      </v-col>
    </v-row>
  </a>
</div>
</template>

<script>
import CurrentEvent from "@/components/CurrentEvent";
export default {
  name: "CurrentEventsPanel",
  components: { CurrentEvent },
  data() {
    return {
      images: [
        {
          fileName: "Elternglueck",
          title: "Eltern&#173;glück"
        },
        {
          fileName: "Geldanlage",
          title: "Geld&#173;anlage"
        },
        {
          fileName: "Immobilie2",
          title: "Baufinan&#173;zierung"
        },
        {
          fileName: "Immobilie",
          title: "Kapital&#173;anlage Immobilie"
        },
        {
          fileName: "Alter",
          title: "Alters&#173;vorsorge"
        },
        {
          fileName: "Alter2",
          title: "Pflege"
        },
      ]
    };
  }
};
</script>

<style scoped>

</style>