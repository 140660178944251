<template>
  <div>
    <v-checkbox hide-details="auto" class="mt-0" v-model="filledOutComp['data']['checked']" :error-messages="errorMessage" @change="change">
      <template v-slot:label>
        <div v-text="component.data.label" class="black--text text-body-1"></div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "ConfirmationView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "EMPTY") {
          return "Bitte bestätigen"
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>