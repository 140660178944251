<template>
  <div>
    <v-select outlined dense hide-details="auto" :label="component['data']['label']"
              :items="numberedItems" item-text="label" item-value="index"
              v-model="filledOutComp.data.selection"
              :success-messages="successMessage" :error-messages="errorMessage"
              @change="change"></v-select>
  </div>
</template>

<script>
export default {
  name: "ChoiceBoxView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    numberedItems() {
      const items = this.component['data']['items'];
      let numberedItems = [];
      for (let i = 0; i < items.length; i++) {
        numberedItems.push({ index: i, label: items[i] });
      }
      return numberedItems;
    },
    successMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "CORRECT") {
          return "Korrekt"
        }
      }
      return "";
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Inkorrekte Antwort"
        }
        if (this.validationResult.status === "EMPTY") {
          return "Bitte eine Option wählen"
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>