<template>
  <div>
    <file-upload :id="component.data.id" :on-change="change" :error="error"></file-upload>
    <div class="mt-n2 red--text text-caption" v-text="error"></div>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload";
export default {
  name: "FileUploadView",
  components: { FileUpload },
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    error() {
      if (this.validationResult) {
        if (this.validationResult.status === "EMPTY") {
          return "Bitte lade eine Datei hoch";
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>