<template>
  <div>

    <div>
      <div class="d-flex align-center mb-1">
        <v-file-input outlined dense truncate-length="false" hide-details="auto" placeholder="Datei auswählen"
                      v-model="currentFile" @change="fileSelected" :error="error !== ''"></v-file-input>
        <v-btn color="primary" class="d-none d-sm-flex ml-2" :outlined2="!uploadPossible" :disabled="!uploadPossible"
               :loading="uploadInProgress" @click="uploadPressed">Hochladen
        </v-btn>
      </div>
      <div class="d-flex justify-end mt-2 mb-1">
        <v-btn color="primary" class="d-sm-none" :outlined2="!uploadPossible" :disabled="!uploadPossible"
               :loading="uploadInProgress" @click="uploadPressed">Hochladen
        </v-btn>
      </div>

      <div v-if="showStatusMessage" class="ml-sm-8 mt-n2">
        <div v-if="errorMessage" class="error--text">{{ errorMessage }}</div>
        <div v-else-if="uploadSucceeded" class="success--text">Datei erfolgreich hochgeladen</div>
      </div>

      <div v-if="uploadInProgress" class="ml-sm-8">
        <v-progress-linear v-model="progress" height="20" striped2 color="primary lighten-1">
          <div :class="progress >= 50 ? 'black--text' : 'black--text'">{{ progress }} %</div>
        </v-progress-linear>
      </div>

      <div v-if="uploadedFiles.length > 0" class="ml-sm-8 mt-1">
        <div v-if="!downloadInProgress">Hochgeladene Datei{{ uploadedFiles.length > 1 ? "en" : "" }}:</div>
        <div v-else class="d-flex align-top">
          Download läuft...
          <v-progress-circular indeterminate size="12" width="1" class="ml-1 mt-1"></v-progress-circular>
        </div>

        <div v-if="downloadProgress > -1" class="">
          <v-progress-linear v-model="downloadProgress" height="20" striped2 color="primary lighten-1">
            <div class="black--text">{{ downloadProgress }} %</div>
          </v-progress-linear>
        </div>

        <v-row class="" dense>
          <v-col class="flex-grow-0 col-12 col-sm" v-for="file in uploadedFiles" :key="file.id">
            <v-btn class="text-none" outlined small style="width: 100%; overflow: hidden" :disabled="downloadInProgress"
                   @click="fileClicked(file)">
              <v-icon left>mdi-file-outline</v-icon>
              {{ file["fileName"] }}
            </v-btn>
          </v-col>
        </v-row>

      </div>

    </div>

    <v-dialog v-model="dialogOpen" max-width="600">
      <v-card>
        <v-card-title>asdf</v-card-title>
        <v-card-text>
          <v-btn>OK</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: ["id", "onChange", "error"],
  data() {
    return {
      currentFile: null,
      progress: 0,
      uploadPossible: false,
      uploadInProgress: false,
      uploadSucceeded: false,
      _uploadFailed: false,
      errorMessage: "",
      showStatusMessage: false,
      uploadedFiles: [],
      downloadInProgress: false,
      downloadProgress: -1,
      dialogOpen: false
    };
  },
  computed: {
    fileTooLarge() {
      if (this.currentFile) {
        if (this.currentFile.size > 22_000_000) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.$store.dispatch("getRequest", ["user-files/" + this.id, this.loadFilesCallback]);
  },
  methods: {
    loadFilesCallback(data) {
      this.uploadedFiles = data;
    },
    fileClicked(file) {
      this.downloadInProgress = true;
      const id = file.id;
      const name = file["fileName"];
      this.$store.dispatch("downloadFile", ["user-files/file/" + id, name, this.downloadProgressCallback, this.downloadCallback, this.downloadCallback]);
    },
    downloadProgressCallback(percentage) {
      this.downloadProgress = percentage;
    },
    downloadCallback() {
      this.downloadInProgress = false;
      this.downloadProgress = -1;
    },
    fileSelected(file) {
      this.progress = 0;

      this.uploadPossible = !!file;

      if (this.fileTooLarge) {
        this.errorMessage = "Datei ist zu groß (max. 20MB)";
        this.showStatusMessage = true;
        this.uploadPossible = false;
      } else {
        this.showStatusMessage = false;
      }

    },
    uploadPressed() {
      this.change();
      if (this.currentFile) {
        this.showStatusMessage = false;
        this.uploadInProgress = true;
        this.$store.dispatch("uploadFile", ["user-files/" + this.id, this.currentFile, this.onUploadProgress, this.onUploadFinished, this.onUploadFailed]);
      }
    },
    onUploadProgress(data) {
      this.progress = Math.min(Math.round((100 * data.loaded) / data.total), 99);
    },
    onUploadFinished(data) {
      this.uploadEnded();
      this.currentFile = null;
      this.uploadPossible = false;
      this.uploadSucceeded = true;
      this.showStatusMessage = true;
      this.errorMessage = "";
      this.uploadSucceeded = true;
      this.uploadedFiles = data;
    },
    onUploadFailed() {
      this.uploadEnded();
      this.errorMessage = "Hochladen fehlgeschlagen";
      this.showStatusMessage = true;
    },
    uploadEnded() {
      this.uploadInProgress = false;
      this.progress = 0;
    },
    change() {
      if (this.onChange) {
        this.onChange();
      }
    }
  }
};
</script>

<style scoped>

</style>