<template>
  <div>
    <v-row dense>
      <v-col class="col-12 col-md-4">
        <v-card tile class="fill-height" :color="$vuetify.theme.themes.light.c_bg_landingpage">
          <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/landing_page/ki.webp"
          ></v-img>
          <v-card-subtitle class="pb-0">
            <div class="partner-text ki-text">Das Karriere-institut</div>
          </v-card-subtitle>
          <v-card-title class="pt-2">KARRIERE. GESTALTEN.</v-card-title>
          <v-card-text class="flex-grow-1">
            Egal ob Student, Berufseinsteiger oder langjähriger Mitarbeiter. Das Karriere-Institut unterstützt dabei,
            die berufliche Zukunft zu gestalten und den persönlichen Erfolg selbst in die Hand zu nehmen.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="#CC1326" text href="https://www.daskarriereinstitut.de/" target="_blank">
              Mehr erfahren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-4">
        <v-card tile class="fill-height" :color="$vuetify.theme.themes.light.c_bg_landingpage">
          <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/landing_page/mn.webp"
          ></v-img>
          <v-card-subtitle class="pb-0">
            <div class="partner-text mn-text">mitNORM</div>
          </v-card-subtitle>
          <v-card-title class="pt-2">Besser mitNORM</v-card-title>
          <v-card-text>
            Unsere Finanzplanung ist objektiv, transparent, nachvollziehbar und verständlich. Unser Konzept wird die
            Finanzbranche nachhaltig verändern und verbraucherfreundlich neu gestalten.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="#004380" text href="https://mitnorm.de/" target="_blank">
              Mehr erfahren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-4">
        <v-card tile class="fill-height" :color="$vuetify.theme.themes.light.c_bg_landingpage">
          <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/landing_page/wp.webp"
          ></v-img>
          <v-card-subtitle class="pb-0">
            <div class="partner-text wp-text">Wirpersonalberater</div>
          </v-card-subtitle>
          <v-card-title class="pt-2">KARRIERE. MACHEN.</v-card-title>
          <v-card-text>
            Irgendeinen Job zu finden ist heutzutage nicht mehr schwer. Den richtigen Job mit dem passenden Arbeitgeber
            dahingegen umso mehr. Wir sind die helfende Hand für eine erfolgreiche Karriere.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="#76C24B" text href="https://www.wirpersonalberater.de/" target="_blank">
              Mehr erfahren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NetworkPartners"
};
</script>

<style scoped>
.partner-text {
  font-weight: 700;
  font-size: 9pt;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ki-text {
  color: rgb(204, 19, 38);
}

.wp-text {
  color: rgb(118, 194, 75);
}

.mn-text {
  color: rgb(0, 67, 128);
}
</style>