<template>
  <v-main>
    <v-container>
      <div class="text-h4 mb-2">Bildgenerator</div>
      <table style="width: 100%">
        <tbody>
        <tr style="vertical-align: top">
          <td class="pr-2" style="white-space: nowrap">Text 1:</td>
          <td style="width: 100%">
            <v-textarea
              id="text1"
              hide-details="auto"
              outlined
              rows="3"
            ></v-textarea>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="d-flex mb-2">
              <v-btn
                class="mt-2"
                color="secondary"
                style="text-transform: none"
                v-on:click="text1Preset1Clicked"
              >
                Dein Vorstellungsgespräch
              </v-btn>
            </div>
          </td>
        </tr>
        <tr style="vertical-align: baseline">
          <td class="pr-2" style="white-space: nowrap">Text 2:</td>
          <td style="width: 100%">
            <v-textarea
              id="text2"
              class="mb-2"
              hide-details="auto"
              outlined
              rows="3"
            ></v-textarea>
          </td>
        </tr>
        <tr style="vertical-align: baseline">
          <td class="pr-2" style="white-space: nowrap">Text 3:</td>
          <td style="width: 100%">
            <v-textarea
              id="text3"
              class="mb2-4"
              hide-details="auto"
              outlined
              rows="3"
            ></v-textarea>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="d-flex mb-2">
              <v-btn
                class="my-2"
                color="secondary"
                style="text-transform: none"
                v-on:click="demo1Clicked"
              >
                Beispiel 1
              </v-btn>
              <v-btn
                class="ml-2 my-2"
                color="secondary"
                style="text-transform: none"
                v-on:click="demo2Clicked"
              >
                Beispiel 2
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="" style="overflow-x: auto; overflow-y: hidden; white-space: nowrap; height: 128px;">
        <v-card
          v-for="(item, index) in templates"
          :key="index"
          :class="'mb-3' + (index === templates.length-1 ? '' : ' mr-3')"
          height="107px"
          :width="item[0] * 107.0"
          v-on:click="imageClicked(index + 1)"
          style="display: inline-block"
        >
          <v-img
            max-height="107px"
            :max-width="item[0] * 107.0"
            :src="getTemplateImage(index + 1, 'small')"
          >
          </v-img>
        </v-card>
      </div>

      <!-- Preview Element -->
      <div class="my-2 d-flex" :style="'width: 100%; position: relative; height:' + previewAreaHeight + 'px'">
        <v-sheet
          elevation="3"
          :width="Math.round(previewWidth)"
          :height="Math.round(previewHeight)"
          style="margin: auto"
        >
          <canvas
            id="previewCanvas"
            :width="Math.round(previewWidth)"
            :height="Math.round(previewHeight)"
          ></canvas>
        </v-sheet>
      </div>


      <v-btn id="downloadButton" class="" color="primary" block v-on:click="downloadPressed()">
        Herunterladen
      </v-btn>


      <!-- full size canvas hidden in an invisible object -->
      <div :style="'max-width: ' + Math.round(fullWidth) + 'px; max-height: ' + Math.round(fullHeight) + 'px; overflow: hidden'" hidden>
        <canvas id="renderCanvas" :width="Math.round(fullWidth)" :height="Math.round(fullHeight)"></canvas>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "InstaGenerator",
  components: {},
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      currentTemplateId: 1,
      output: null,
      canvasContext: null,
      templates: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      maxPreviewWidth: 100
    };
  },
  computed: {
    previewAreaHeight: function() {
      // height - bar - upper & lower margins - (app bar) - download button - margin y - bg selector
      let height = this.windowHeight - 48 - 24 - (this.$vuetify.breakpoint.xs ? 48 : 0) - 36 - 16 - 128;
      return Math.max(150, height);
    },
    previewHeight: function() {
      // height - bar - upper & lower margins - (app bar) - download button - margin y - bg selector
      let maxHeight = this.windowHeight - 48 - 24 - (this.$vuetify.breakpoint.xs ? 48 : 0) - 36 - 16 - 128;
      let heightForMaxWidth = this.maxPreviewWidth / this.templates[this.currentTemplateId - 1][0];
      return Math.max(150, Math.min(maxHeight, heightForMaxWidth));
    },
    previewWidth: function() {
      return this.previewHeight * this.templates[this.currentTemplateId - 1][0];
    },
    fullWidth: function() {
      return 1080;
    },
    fullHeight: function() {
      return this.fullWidth / this.templates[this.currentTemplateId - 1][0]
    }
  },
  watch: {},
  created() {
    window.addEventListener("resize", this.onResize);
    this.createTemplatePresets();
  },
  mounted() {
    this.onResize();
    this.currentTemplateId = 1;
    let canvas = document.getElementById("previewCanvas");
    this.draw(canvas, false, true);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    createTemplatePresets() {
      const factor16To9 = 0.5625;

      const fullWidth = 900;
      const borderedWidth = 780;
      const font = "Roboto";
      const fontWeight = 400;
      const fontWeightMedium = 600;
      const middleX = 540;

      // colors
      const darkOnGray = "#383838";
      const grayOnWhite = "#383838";
      const grayOnRed = "#D8D8D8";
      const whiteOnRed = "#E8E8E8";
      const text2Size = 100;

      this.templates = [
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 320, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, darkOnGray, middleX, 960, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, grayOnWhite, middleX, 320, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, grayOnWhite, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, 960, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 320, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, darkOnGray, middleX, 960, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, whiteOnRed, middleX, 320, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, whiteOnRed, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, whiteOnRed, middleX, 960, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 280, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, 930, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, 890, true, true, font, fontWeightMedium, 700, 1.5]
        ],
        [
          factor16To9,
          [true, false, grayOnWhite, middleX, 360, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, grayOnWhite, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, whiteOnRed, middleX, 960, true, false, font, fontWeightMedium, 800, 1.5]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 360, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, 1050, true, true, font, fontWeightMedium, 700, 1.2]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 360, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, 960, true, false, font, fontWeightMedium, 800, 1.5]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 575, true, true, font, fontWeight, fullWidth, 1.5],
          [false, false, grayOnWhite, middleX, 1065, true, true, font, fontWeight, 120, 1.5],
          [true, false, whiteOnRed, middleX, 1515, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, grayOnWhite, middleX, 320, true, false, font, fontWeight, borderedWidth, 1.5],
          [false, true, grayOnWhite, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, 960, true, true, font, fontWeightMedium, borderedWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, darkOnGray, middleX, 320, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, darkOnGray, middleX, 960, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          factor16To9,
          [true, false, whiteOnRed, middleX, 370, true, false, font, fontWeight, borderedWidth, 1.5],
          [false, true, whiteOnRed, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, whiteOnRed, middleX, 1020, true, true, font, fontWeightMedium, borderedWidth, 1.5]
        ],
        [
          1,
          [true, false, darkOnGray, middleX, 200, true, false, font, fontWeight, fullWidth, 1.5],
          [false, false, darkOnGray, 350, 920, true, true, font, fontWeight, text2Size, 1.2],
          [true, false, darkOnGray, middleX, middleX, true, true, font, fontWeightMedium, 900, 1.5]
        ],
        [
          1,
          [true, false, darkOnGray, middleX, 200, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, darkOnGray, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, darkOnGray, middleX, 725, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          1,
          [true, false, darkOnGray, middleX, 200, true, false, font, fontWeight, fullWidth, 1.5],
          [false, false, darkOnGray, middleX, 940, true, true, font, fontWeight, text2Size, 1.2],
          [true, false, darkOnGray, middleX, 640, true, true, font, fontWeightMedium, 900, 1.5]
        ],
        [
          1,
          [true, false, whiteOnRed, middleX, 200, true, false, font, fontWeight, fullWidth, 1.5],
          [false, true, whiteOnRed, middleX, 50, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, whiteOnRed, middleX, 725, true, true, font, fontWeightMedium, fullWidth, 1.5]
        ],
        [
          1,
          [true, false, grayOnWhite, 2000, 200, false, false, font, fontWeight, fullWidth, 1.5],
          [false, false, grayOnWhite, middleX, 200, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, grayOnWhite, middleX, middleX, true, true, font, fontWeightMedium, 650, 1.5]
        ],
        [
          1,
          [true, false, darkOnGray, middleX, 220, true, false, font, fontWeight, fullWidth, 1.5],
          [false, false, whiteOnRed, middleX, 415, true, false, font, fontWeight, text2Size, 1.5],
          [true, false, whiteOnRed, middleX, 570, true, false, font, fontWeightMedium, 800, 1.5]
        ],
        [
          1,
          [true, false, darkOnGray, middleX, 200, true, false, font, fontWeight, fullWidth, 1.5],
          [false, false, darkOnGray, middleX, 920, true, true, font, fontWeight, text2Size, 1.0],
          [true, false, darkOnGray, middleX, 565, true, true, font, fontWeightMedium, 900, 1.5]
        ]
      ];
      // fit text, attach to predecessor

    },
    onResize: function() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      let element = document.getElementById("downloadButton");
      if (element != null) {
        this.maxPreviewWidth = element.offsetWidth;
      } else {
        this.maxPreviewWidth = 100;
      }
    },
    text1Preset1Clicked() {
      document.getElementById("text1").value = "Dein Vorstellungsgespräch";
    },
    demo1Clicked() {
      document.getElementById("text2").value = "#1";
      document.getElementById("text3").value = "Welche Fragen stellen\nsich die Unternehmen\nüber dich?";
    },
    demo2Clicked() {
      document.getElementById("text2").value = "#2";
      document.getElementById("text3").value = "Welche Fragen\nstellen sich\ndie Unternehmen\nüber dich?";
    },
    getTemplateImage: function(id, size) {
      return require("../assets/images/generator_templates/" + size + "/template (" + id + ").jpg");
    },
    imageClicked: function(id) {
      this.currentTemplateId = id;
      var canvas = document.getElementById("previewCanvas");
      this.draw(canvas, false, false);
    },
    downloadPressed() {
      var canvas = document.getElementById("renderCanvas");
      this.draw(canvas, true, false);
    },
    draw(canvas, fullRender, initialRender) {
      if (canvas.getContext) {
        let ctx = canvas.getContext("2d");

        let w = 0;
        let h = 0;

        if (fullRender) {
          w = this.fullWidth;
          h = this.fullHeight;
        } else {
          w = this.previewWidth;
          h = this.previewHeight;
        }
        let scale = w / this.fullWidth;

        ctx.clearRect(0, 0, w, h);

        let imageSource = this.getTemplateImage(this.currentTemplateId, "large");

        var base_image = new Image();
        base_image.src = imageSource;

        var downloadCallback = this.download;
        const template = this.templates[this.currentTemplateId - 1];

        var fillTextAdvFitToWidth = function(
          text, posX, posY, centerX, centerY, font, fontWeight, fitWidth, lineHeight
        ) {
          var lines = text.split("\n");

          // set font to get starting width
          ctx.font = 80 + "px " + font;

          // loop through lines and find largest one
          var maxWidth = 0;
          for (var i = 0; i < lines.length; i++) {
            maxWidth = Math.max(maxWidth, ctx.measureText(lines[i]).width);
          }

          var fontSize = (80 * fitWidth) / maxWidth;

          return fillTextAdv(
            text,
            posX,
            posY,
            centerX,
            centerY,
            font,
            fontWeight,
            fontSize,
            lineHeight
          );
        };

        var fillTextAdv = function(
          text,
          posX,
          posY,
          centerX,
          centerY,
          font,
          fontWeight,
          fontSize,
          lineHeight
        ) {
          // do all calculations with scale 1 full width and convert when drawing
          var lines = text.split("\n");
          var lineCount = lines.length;
          var lineHeightPx = fontSize * lineHeight;
          var totalHeight =
            lineCount * fontSize +
            (lineCount - 1) * fontSize * (lineHeight - 1);

          // shift posY to place 0 at upper left corner of first line
          posY = posY + fontSize;

          if (centerY) {
            // shift posY to place 0 at half height of total height
            posY = posY - totalHeight / 2;
          }

          // draw 0 point
          ctx.beginPath();
          ctx.rect(
            (posX - 5) * scale,
            (posY - 5) * scale,
            10 * scale,
            10 * scale
          );
          //ctx.fill();

          // prepare text draw
          ctx.font = fontWeight + " " + fontSize * scale + "px " + font;
          ctx.textAlign = centerX ? "center" : "left";

          // draw text line by line
          for (var i = 0; i < lineCount; i++) {
            ctx.fillText(
              lines[i],
              posX * scale,
              (posY + i * lineHeightPx) * scale
            );
          }

          // return y position of last pixel in text
          return posY + (lineCount - 1) * lineHeightPx;
        };

        base_image.onload = function() {
          // draw background image
          ctx.drawImage(base_image, 0, 0, w, h);

          // draw text
          let texts = [
            document.getElementById("text1").value,
            document.getElementById("text2").value,
            (initialRender ? "Klicke auf einen Hintergrund,\n um eine Vorschau zu sehen" : document.getElementById("text3").value)
          ];

          /*
          texts = [
            "Dein Vorstellungsgespräch",
            "#2",
            "Welche Fragen stellen\nsich die Unternehmen\nüber dich?"
          ]
           */

          let lastY = 0;
          for (let i = 0; i < 3; i++) {
            let text = texts[i];
            let textSettings = template[i + 1];
            let fitToWidth = textSettings[0];
            let insertAfterPredecessor = textSettings[1];
            let color = textSettings[2];
            textSettings = textSettings.slice(3);

            ctx.fillStyle = color;

            if (insertAfterPredecessor) {
              textSettings[1] = lastY + textSettings[1];
            }

            if (fitToWidth) {
              lastY = fillTextAdvFitToWidth(text, ...textSettings);
            } else {
              lastY = fillTextAdv(text, ...textSettings);
            }

          }

          if (fullRender) {
            downloadCallback();
          }
        };
      }
    },
    download() {
      var link = document.createElement("a");
      link.download = "KI-Bild.png";
      link.href = document.getElementById("renderCanvas").toDataURL();
      link.click();
    }
  }
};
</script>

<style scoped></style>
