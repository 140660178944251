<template>
  <v-card>
    <v-card-title class="text-h5 text-sm-h4 progress-headline justify-center">
      Mein Personal Leadership
    </v-card-title>
    <v-card-text>
      <div ref="circleAreaReference">
        <div v-if="multiline">
          <div class="d-flex justify-center">
            <ProgressCircle3 v-for="(circle, index) in circles" v-if="index < 3" :key="index" :diameter="diameter"
                             :color-prop="circle.color" :pronoun="circle.pronoun" :course="circle.name"
                             :percentage="courseProgress[index]" :style="'margin: 0px ' + marginX + 'px'"></ProgressCircle3>
          </div>
          <div class="d-flex justify-center mt-3">
            <ProgressCircle3 v-for="(circle, index) in circles" v-if="index > 2" :key="index" :diameter="diameter"
                             :color-prop="circle.color" :pronoun="circle.pronoun" :course="circle.name"
                             :percentage="courseProgress[index]" :style="'margin: 0px ' + marginX + 'px'"></ProgressCircle3>
          </div>
        </div>
        <div v-else class="d-flex flex-wrap justify-space-between mx-md-2 my-md-2 mx-lg-8 my-lg-4">
          <ProgressCircle3 v-for="(circle, index) in circles" :key="index" :diameter="diameter"
                           :color-prop="circle.color" :pronoun="circle.pronoun" :course="circle.name"
                           :percentage="courseProgress[index]"></ProgressCircle3>
        </div>
      </div>

    </v-card-text>
  </v-card>
</template>

<script>
import ProgressCircle3 from "@/components/ProgressCircle3";

export default {
  name: "ProgressCircleCard",
  components: { ProgressCircle3 },
  props: ["courseProgress"],
  data: () => ({
    diameter: 0,
    multiline: false,
    marginX: 0,
    circles: [
      { pronoun: "", name: "Einführung", progress: 100, color: "#3c9b9c" },
      { pronoun: "Mein", name: "Selbst", progress: 80, color: "#AB4642" },
      { pronoun: "Mein", name: "Umfeld", progress: 60, color: "#DC9656" },
      { pronoun: "Meine", name: "Karriere", progress: 40, color: "#76b82a" },
      { pronoun: "Meine", name: "Finanzen", progress: 20, color: "#044380" },
      { pronoun: "Meine", name: "Gesundheit", progress: 10, color: "#A26F97" }
    ],
  }),
  mounted() {
    window.addEventListener("resize", this.resizeListener);
    this.resizeListener();
  },
  methods: {
    resizeListener() {
      try {
        const width = this.$refs.circleAreaReference.clientWidth;
        this.calculate(width);
      } catch (e) {
      }
    },
    calculate(width) {
      let multiline = false;
      let circlesInLine = 6.0;
      if (width < 500) {
        multiline = true;
        circlesInLine = 3.0;
      }
      this.multiline = multiline;

      if (multiline) {
        this.diameter = Math.min(width / circlesInLine * 0.9, 90);
        this.marginX = (width - this.diameter * circlesInLine) / 10.0;
      } else {
        this.diameter = width / circlesInLine * 0.85;
      }

      this.diameter = Math.round(this.diameter);

    }
  }
};
</script>

<style scoped>
.progress-headline {
  font-weight: 300;
}
</style>