<template>
  <div>

    <!-- avatar banner -->
    <v-sheet elevation="2" rounded class="py-1 d-lg-none">
      <div class="d-flex justify-center align-center mt-1 mb-1">
        <router-link :to="{name: 'AboutMe'}">
          <v-avatar color="primary" size="64" class="elevation-1">
            <img src="@/assets/images/roy/head.jpg">
          </v-avatar>
        </router-link>
        <div class="nice-avatar-banner text-center ml-3">Schön, dass du da bist!</div>
      </div>
    </v-sheet>
    <!-- /avatar banner -->

    <v-row class="no-gutters2 mx-1 mx-sm-n1" dense>

      <v-col class="d-none d-lg-block col-2">
        <v-sheet elevation="1" class="" rounded2>
          <router-link :to="{name: 'AboutMe'}">
            <v-img src="@/assets/images/roy/home_crop.jpg" class="elevation-1" min-width="186" min-height="231"></v-img>
          </router-link>
          <div v-if="false" class="nice text-center py-1">Schön, dass du da bist!</div>
        </v-sheet>
        <div v-if="true" class="nice2 text-center mt-1">Schön, dass du da bist!</div>
      </v-col>

      <v-col class="d-none d-md-block col-12 col-md-9 col-lg-8">
        <v-card class="fill-height">
          <v-card-title class="pb-2">Werde zum Held deines Lebens</v-card-title>
          <v-card-text class="black--text">Übernimm Verantwortung und Führung für den Erfolg und deine Zufriedenheit in deinem Leben. Egal
            ob du Herausforderungen mit deinen Finanzen, deiner Karriereplanung, deinen Beziehungen, deiner Gesundheit
            oder mit persönlichen Problemen hast, ich werde mit meinem Netzwerk und den Online-Kursen alles geben,
            deine Probleme und Herausforderungen zu lösen. Ich möchte dich bei der Umsetzung deines
            Personal Leaderships begleiten.
            <br>Ich freue mich, dass du heute da bist. Du bist großartig!
            <br>Herzliche Grüße und viel Erfolg beim Umsetzen!
            <br>Dein Roy
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-3 col-lg-2" v-if="true">
        <v-card class="fill-height">
          <v-card-title class="pa-3 justify-center">Buchempfehlung</v-card-title>
          <v-card-text class="">
            <RecommendedReading></RecommendedReading>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <div class="mt-1" v-if="true">
      <v-card>
        <v-card-title class="py-3 justify-center justify-md-start">Aktuelle Veranstaltungen</v-card-title>
        <v-card-text>
          <CurrentEventsPanel></CurrentEventsPanel>
        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
import RecommendedReading from "@/components/RecommendedReading";
import CurrentEventsPanel from "@/components/CurrentEventsPanel";

export default {
  name: "HomeMainPanel",
  components: { CurrentEventsPanel, RecommendedReading }
};
</script>

<style scoped>

.nice {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-size: 14pt;
  font-weight: 500;
  transform: translateX(-4px);
}

.nice2 {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-size: 15pt;
  font-weight: 500;
  transform: translateX(-4px);
}

.nice-avatar-banner {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-size: 15pt;
  font-weight: 500;
}

</style>