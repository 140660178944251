import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import mitt from "mitt";
import socialLoginOutdated from "@/components/SocialLoginOutdated.vue";

window.mitt = window.mitt || new mitt();

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default new Vuex.Store({
  state: {
    automaticLoginFailed: false,
    userIsLoggedIn: false,
    errorWhileLoggingIn: false,
    routeAfterLogin: "",
    applicationData: {},
    applicationDataDicts: {},
    cachedData: {},
    apiCache: {},
    roles: [],
    errorCallback: null,
    referralCode: null
  },
  mutations: {
    setAutomaticLoginFailed(state, status) {
      state.automaticLoginFailed = status;
    },
    setUserIsLoggedIn(state, status) {
      state.userIsLoggedIn = status;
    },
    setErrorWhileLoggingInStatus(state, status) {
      state.errorWhileLoggingIn = status;
    },
    setRouteAfterLogin(state, route) {
      state.routeAfterLogin = route;
    },
    SET_APPLICATION_DATA_AND_DICT(state, [dataType, data]) {
      Vue.set(state.applicationData, dataType, data);
      let dict = {};
      for (let entry of data) {
        // eslint-disable-next-line no-prototype-builtins
        if (!entry.hasOwnProperty("id")) {
          return;
        }
        dict[entry.id] = entry;
      }
      Vue.set(state.applicationDataDicts, dataType, dict);
    },
    SET_APPLICATION_DATA(state, [endpoint, data]) {
      Vue.set(state.applicationData, endpoint, data);
    },
    ADD_TO_APPLICATION_DATA_AND_DICT(state, [endpoint, entry]) {
      state.applicationData[endpoint].push(entry);
      Vue.set(state.applicationDataDicts[endpoint], entry.id, entry);
    },
    REMOVE_FROM_APPLICATION_DATA_AND_DICT(state, [endpoint, id]) {
      let index = -1;
      let array = state.applicationData[endpoint];
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          index = i;
        }
      }
      array.splice(index, 1);
      Vue.delete(state.applicationDataDicts[endpoint], id);
    },
    SET_CACHED_DATA(state, [endpoint, data]) {
      Vue.set(state.cachedData, endpoint, data);
    },
    cacheApiResponse(state, [endpoint, data]) {
      Vue.set(state.apiCache, endpoint, data);
    },
    updateRoles(state, rolesString) {
      if (rolesString) {
        state.roles = rolesString.split(", ");
      } else {
        state.roles = [];
      }
    },
    setErrorCallback(state, errorCallback) {
      state.errorCallback = errorCallback;
    },
    showError(state, error) {
      state.errorCallback(error);
    },
    handleResponse(state, response) {
      if ("accepted-terms" in response) {
        if (!response["accepted-terms"].includes("true")) {
          window.mitt.emit("acceptTermsRequest");
          return false;
        }
      }
      return true;
    },
    handleError(state, error) {
      if (error.response) {
        state.errorCallback(error.response.status + " - " + error.response.config.url);
      } else if (error.request) {
        state.errorCallback("Server nicht erreichbar");
      } else {
        state.errorCallback(error.message);
      }
    },
    setReferralCode(state, code) {
      state.referralCode = code;
    }
  },
  actions: {
    showError({ commit }, text) {
      commit("showError", text);
    },
    attemptLoginWithCookieNew({ commit }, callback) {
      let token = Vue.$cookies.get("roy-spitzner-custom-token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        // check token
        axios.get("/check-authentication")
          .then(() => {
            commit("setUserIsLoggedIn", true);
            callback(true);
          })
          .catch(() => {
            commit("setUserIsLoggedIn", false);
            callback(false);
          });
      } else {
        commit("setUserIsLoggedIn", false);
        callback(false);
      }
    },
    loginNew({ commit }, [payload, callback]) {

      callback({ success: false });
      return;

      axios.post("/login", payload, { timeout: 20000 })
        .then(response => {
          let token = response.headers.authorization;
          axios.defaults.headers.common["Authorization"] = token;
          Vue.$cookies.set("ki-authentication-token", token);
          commit("setUserIsLoggedIn", true);
          if (callback) {
            callback({ success: true });
          }
        })
        .catch(error => {
          commit("setUserIsLoggedIn", false);
          let result = { success: false };
          if (error.response) {
            if (error.response.status === 403 || error.response.status === 401) {
              if (error.response.headers["account-confirmed"] === "false") {
                result["accountNotConfirmed"] = true;
              }
              result["errorMessage"] = "Die Anmeldedaten sind nicht korrekt.";
            } else if (error.response.status === 404) {
              result["errorMessage"] = "Keine Verbindung zum Server. Offenbar gibt es technische Probleme. Bitte versuch es später noch einmal.";
            } else if (error.response.status >= 500) {
              result["errorMessage"] = "Interner Fehler bei der Anmeldung. Offenbar gibt es technische Probleme. Bitte versuch es später noch einmal.";
            } else {
              result["errorMessage"] = "Unbekannter Fehlercode: " + error.response.status;
            }
          } else if (error.request) {
            result["errorMessage"] = "Keine Verbindung zum Server. Offenbar gibt es technische Probleme. Bitte versuch es später noch einmal.";
          } else {
            result["errorMessage"] = error.message;
          }
          if (callback) {
            callback(result);
          }
        });
    },
    processFirebaseResponse({ commit }, [response, callback]) {
      if (response) {
        let firebaseToken = response["user"]["_delegate"]["accessToken"];
        let result = { success: false };
        axios.post("/token/get-custom-token", { firebaseToken: firebaseToken })
          .then(response => {
            let token = response.data["token"];
            if (token !== null && token !== undefined && token !== "" && token !== "null" && token !== "undefined") {
              axios.defaults.headers.common["Authorization"] = token;
              Vue.$cookies.set("roy-spitzner-custom-token", token, -1);
              result["success"] = true;
              commit("setUserIsLoggedIn", true);
            } else {
              result["errorMessage"] = "Fehler bei der Anmeldung";
            }
            if (callback) {
              callback(result);
            }
          })
          .catch(error => {
            commit("handleError", error);
            if (callback) {
              callback(result);
            }
          });
      }
    },
    signup({ commit }, [payload, callback]) {
      axios.post("/signup", payload, { timeout: 10000 })
        .then(() => {
          callback(true);
        })
        .catch(error => {
          if (error.response) {
            let status = error.response.status;
            let errorType = error.response.data["errorType"];
            if (status === 400) {
              if (errorType === "email_in_use") {
                callback("Diese E-Mail Adresse wird bereits verwendet");
              } else if (errorType === "not_checked") {
                callback("Checkbox Fehler");
              } else if (errorType === "invalid_email") {
                callback("Diese E-Mail Adresse ist ungültig");
              } else if (errorType === "password_too_short") {
                callback("Das Passwort ist zu kurz");
              } else if (errorType === "password_too_long") {
                callback("Das Passwort ist zu lang");
              } else if (errorType === "password_no_lowercase") {
                callback("Das Passwort muss mindestens einen Kleinbuchstaben enthalten (a-z)");
              } else if (errorType === "password_no_uppercase") {
                callback("Das Passwort muss mindestens einen Großbuchstaben enthalten (A-Z)");
              } else if (errorType === "password_no_number") {
                callback("Das Passwort muss mindestens eine Ziffer enthalten (0-9)");
              } else if (errorType === "password_no_symbol") {
                callback("Das Passwort muss mindestens ein Sonderzeichen enthalten");
              } else {
                callback("Unbekannter Fehler: " + errorType);
              }
            } else if (error.response.status >= 500) {
              callback("Technischer Fehler bei der Anmeldung.");
            } else {
              callback("Unbekannter Fehler: " + error.response.status);
            }
          } else if (error.request) {
            callback("Keine Verbindung zum Server. Offenbar gibt es technische Probleme.");
          } else {
            callback(error.message);
          }
        });
    },
    fetchRolesAfterLogin({ commit }, data) {
      // TODO
    },
    getApplicationData({ commit }, dataType) {
      axios.get("/" + dataType)
        .then(response => {
          commit("SET_APPLICATION_DATA_AND_DICT", [dataType, response.data]);
          commit("updateRoles", response.headers["roles"]);
        });
    },
    getApplicationDataSingleEntry({ commit }, endpoint) {
      axios.get("/" + endpoint)
        .then(response => {
          commit("SET_APPLICATION_DATA", [endpoint, response.data]);
          commit("updateRoles", response.headers["roles"]);
        });
    },
    getApplicationDataCallback({ commit }, [endpoint, callback]) {
      axios.get("/" + endpoint)
        .then(response => {
          commit("updateRoles", response.headers["roles"]);
          callback(response.data);
        });
    },
    getRequest({ commit }, [endpoint, callback]) {
      axios.get("/" + endpoint)
        .then(response => {
          commit("updateRoles", response.headers["roles"]);

          if ("accepted-terms" in response.headers) {
            if (!response.headers["accepted-terms"].includes("true")) {
              window.mitt.emit("acceptTermsRequest");
              return;
            }
          }

          if (callback) {
            callback(response.data);
          }

        })
        .catch(reason => {
          commit("handleError", reason);
        });
    },
    getRequestPromise({ commit }, endpoint) {
      return new Promise((resolve, reject) => {
        axios.get("/" + endpoint)
          .then(response => {
            commit("updateRoles", response.headers["roles"]);
            commit("cacheApiResponse", [endpoint, response.data]);
            resolve(response.data);
          })
          .catch(reason => {
            commit("handleError", reason);
            reject(reason);
          });
      });
    },
    postApplicationDataCallback({ commit }, [endpoint, data, callback]) {
      axios.post("/" + endpoint, data)
        .then(response => {
          commit("updateRoles", response.headers["roles"]);
          callback(response.data);
        });
    },
    postRequest({ commit }, [endpoint, data, successCallback, errorCallback]) {
      axios.post("/" + endpoint, data)
        .then(response => {
          commit("updateRoles", response.headers["roles"]);
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch(reason => {
          commit("handleError", reason);
          if (errorCallback) {
            errorCallback(reason);
          }
        });
    },
    postRequestPromise({ commit }, [endpoint, data]) {
      return new Promise((resolve, reject) => {
        axios.post("/" + endpoint, data)
          .then(response => {
            commit("updateRoles", response.headers["roles"]);
            resolve(response.data);
          })
          .catch(reason => {
            commit("handleError", reason);
            reject(reason);
          });
      });
    },
    putApplicationDataSingleEntry({ commit }, [endpoint, data, contentType]) {
      axios.put("/" + endpoint, data, { headers: { "Content-Type": contentType } })
        .then(response => {
          commit("SET_APPLICATION_DATA", [endpoint, response.data]);
          commit("updateRoles", response.headers["roles"]);
        });
    },
    putRequest({ commit }, [endpoint, data, callback, identifier]) {
      axios.put("/" + endpoint, data)
        .then(response => {
          commit("updateRoles", response.headers["roles"]);
          if (callback) {
            callback(response.data, identifier);
          }
        })
        .catch(reason => {
          commit("handleError", reason);
          if (callback) {
            callback(null, identifier);
          }
        });
    },
    putRequestPromise({ commit }, [endpoint, data]) {
      return new Promise((resolve, reject) => {
        axios.put("/" + endpoint, data)
          .then(response => {
            commit("updateRoles", response.headers["roles"]);
            resolve(response.data);
          })
          .catch(reason => {
            commit("handleError", reason);
            reject(reason);
          });
      });
    },
    deleteApplicationData({ commit }, [endpoint, element]) {
      axios.delete("/" + endpoint + "/" + element)
        .then(response => {
          commit("SET_APPLICATION_DATA_AND_DICT", [endpoint, response.data]);
          commit("updateRoles", response.headers["roles"]);
        });
    },
    deleteApplicationDataCallback({ commit }, [endpoint, elementId, callback]) {
      axios.delete("/" + endpoint + "/" + elementId)
        .then(response => {
          if (callback) {
            callback(response.data);
          }
        });
    },
    deleteRequest({ commit }, [endpoint, callback]) {
      axios.delete("/" + endpoint)
        .then(response => {
          if (callback) {
            callback(response.data);
          }
        })
        .catch(reason => {
          commit("handleError", reason);
        });
    },
    deleteRequestPromise({ commit }, endpoint) {
      return new Promise((resolve, reject) => {
        axios.delete("/" + endpoint)
          .then(response => {
            resolve(response.data);
          })
          .catch(reason => {
            commit("handleError", reason);
            reject(reason);
          });
      });
    },
    uploadFile({ commit }, [endpoint, file, uploadProgress, uploadFinished, uploadFailed]) {
      let formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: uploadProgress
      };
      axios.post("/" + endpoint, formData, config)
        .then(response => {
          if (uploadFinished) {
            uploadFinished(response.data);
          }
        })
        .catch(reason => {
          if (uploadFailed) {
            uploadFailed(reason);
          }
        });
    },
    downloadFile({ commit }, [endpoint, fileName, progressCallback, successCallback, errorCallback]) {
      axios.get("/" + endpoint, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          if (progressCallback) {
            if (progressEvent.lengthComputable) {
              progressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            } else {
              progressCallback(-1);
            }
          }
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        //link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        if (successCallback) {
          successCallback(response.data);
        }
      }).catch(reason => {
        if (errorCallback) {
          errorCallback(reason);
        }
        commit("handleError", reason);
      });
    },
    downloadFilePromise({ commit }, [endpoint, fileName]) {
      return new Promise((resolve, reject) => {
        axios.get("/" + endpoint, {
          responseType: "blob"
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          resolve(response.data);
        }).catch(reason => {
          commit("handleError", reason);
          reject(reason);
        });
      });
    },
    updateReferralCode({ commit }, code) {
      commit("setReferralCode", code);
    },
    showConfirmDialog({ commit }, details) {
      return new Promise((resolve, reject) => {
        const id = Date.now();
        window.mitt.on("confirmDialogResult", response => {
          if (response.id === id) {
            if (response.result) {
              resolve();
            } else {
              reject();
            }
          }
        });
        details.id = id;
        window.mitt.emit("showConfirmDialog", details);
      });
    }
  },
  getters: {
    userIsLoggedIn: state => {
      return state.userIsLoggedIn;
    },
    cachedData: state => {
      return state.cachedData;
    },
    referralCode: state => {
      return state.referralCode;
    },
    apiCache(state) {
      return (endpoint) => {
        if (state.apiCache[endpoint]) {
          return state.apiCache[endpoint];
        } else {
          return null;
        }
      };
    }
  },
  modules: {}
});
