<template>
  <div ref="outerContainer" class="outerContainer d-flex justify-center">
    <div class="innerContainer" :style="'width: ' + containerWidth + 'px; height: ' + containerWidth*1.0 + 'px'">
      <DemoCircle :scale="scale" :pos-x="267" :pos-y="142" title="Selbstfindung" :start-delay="1000" color="#AB4642"></DemoCircle>
      <DemoCircle :scale="scale" :pos-x="733" :pos-y="142" title="Persönliches Umfeld" :start-delay="1500" color="#DC9656"></DemoCircle>
      <DemoCircle :scale="scale" :pos-x="877" :pos-y="585" title="Gesundheit" :start-delay="2000" color="#A26F97"></DemoCircle>
      <DemoCircle :scale="scale" :pos-x="500" :pos-y="860" title="Finanzen" :start-delay="2500" color="#044380"></DemoCircle>
      <DemoCircle :scale="scale" :pos-x="123" :pos-y="585" title="Karriereplanung" :start-delay="3000" color="#76b82a"></DemoCircle>
      <DemoCircle :scale="scale" :pos-x="500" :pos-y="480" title="Grundsätze der<br>Selbstführung" :start-delay="500" color="#3c9b9c"></DemoCircle>
    </div>
  </div>
</template>

<script>
import ProgressCircle3 from "@/components/ProgressCircle3";
import DemoCircle from "@/components/landing_page/demo_circles/DemoCircle";

export default {
  name: "DemoCirclesContainer",
  components: { DemoCircle, ProgressCircle3 },
  data: () => ({
    outerContainerWidth: 0
  }),
  mounted() {
    window.addEventListener("resize", this.resizeListener);
    this.resizeListener();
  },
  computed: {
    containerWidth() {
      return Math.min(this.outerContainerWidth, 600);
    },
    scale() {
      return this.containerWidth / 1000.0;
    }
  },
  methods: {
    resizeListener() {
      try {
        this.outerContainerWidth = this.$refs.outerContainer.clientWidth;
      } catch (e) {
      }
    }
  }
};
</script>

<style scoped>
.outerContainer {
  background-color2: rgba(255, 255, 255, 0.1);
}

.innerContainer {
  background-color2: green;
  position: relative;
}
</style>