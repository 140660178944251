<template>
  <div class="circle-container" :style="'position: absolute; top: ' + posYCalc + 'px; left: ' + posXCalc + 'px;'">
    <div class="d-flex flex-column align-center">
      <div class="title-text mb-2" v-html="title" :style="'font-size: ' + fontSizeCalc + 'px'"></div>
      <v-progress-circular :size="225 * scale" :width="10 * scale" :value="value" rotate="-90" :color="color">
        <div v-text="percentage + '%'" class="circle-text" :style="'font-size: ' + percentageFontSizeCalc + 'px'"></div>
      </v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoCircle",
  props: {
    scale: Number,
    posX: Number,
    posY: Number,
    title: String,
    color: String,
    startDelay: Number
  },
  data: () => ({
    percentage: 0,
    value: 0,
    delay: 1
  }),
  computed: {
    posXCalc() {
      return this.posX * this.scale
    },
    posYCalc() {
      return this.posY * this.scale
    },
    fontSizeCalc() {
      return Math.round(40 * this.scale)
    },
    percentageFontSizeCalc() {
      return Math.round(50 * this.scale)
    }
  },
  mounted() {
    this.delayAnimation()
  },
  methods: {
    delayAnimation() {
      let reference = this.increaseValue
      setTimeout(function() {
        reference()
      }, this.startDelay)
    },
    increaseValue() {
      this.percentage += 3;
      this.delay = this.delay + this.delay * 0.1;

      //this.delay = Math.min(this.delay, 30);

      if (this.percentage > 10 && this.value !== 100) {
        this.value = 100
      }
      //this.value = Math.floor(this.percentage / 10) * 10

      if (this.percentage > 100) {
        this.percentage = 100
      }
      if (this.percentage < 100) {
        let reference = this.increaseValue
        setTimeout(function() {
          reference()
        }, this.delay)
      }
    }
  }
};
</script>

<style scoped>
.circle-container {
  transform: translate(-50%, -50%);
}
.title-text {
  line-height: 1.2;
  text-align: center;
}
.circle-text {
  color: #4f4f4f;
}
</style>