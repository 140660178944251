<template>
  <v-progress-circular :rotate="-90" :size="diameter" :width="width" :value="Math.max(percentage, 0)" :color="color"
                       :class="percentage === 100 ? 'icon' : ''">
    <div class="d-flex flex-column align-center grey--text text--darken-4" :style="circleTextStyle">
      <div v-text="pronoun"></div>
      <div v-if="course" v-text="course" class="mb-1 mb-md-2 mb-lg-3"></div>
      <div v-text="percentage >= 0 ? percentage + '%' : '...'" class="grey--text text--darken-1"
           style="font-weight: 300"></div>
    </div>
  </v-progress-circular>
</template>

<script>
export default {
  name: "ProgressCircle3",
  props: {
    diameter: Number,
    colorProp: String,
    pronoun: String,
    course: String,
    percentage: {
      type: Number,
      default: -1
    }
  },
  data: () => ({
    value: 0,
    circleTextStyle: {
      'font-family': '"Caveat", "Ink Free", cursive;'
    }
  }),
  computed: {
    width() {
      return Math.round(3 + this.diameter * 0.015);
    },
    color() {
      if (this.percentage === 100) {
        return "#e2b007";
      } else {
        return this.colorProp;
      }
    }
  },
  watch: {
    diameter() {
      this.calculate();
    }
  },
  mounted() {
    let percentage = this.percentage;
    setTimeout(function () {
      this.updateValue(percentage);
    }.bind(this), 500);
    this.calculate();
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    calculate() {
      // 532
      const dia = this.diameter - 71;
      this.circleTextStyle['font-size'] = 8.5 + dia * 0.08 + 'pt';
      this.circleTextStyle['line-height'] = Math.round(14 + dia * 0.15) + 'px';
    }
  }
};
</script>

<style scoped>

.icon {
  overflow: hidden;
}

.icon:after {

  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(-30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.0) 0%,
      rgba(255, 255, 255, 0.0) 77%,
      rgba(255, 255, 255, 1) 92%,
      rgba(255, 255, 255, 0.0) 100%
  );
}

/* Hover state - trigger effect */


/* Active state */

.icon:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

</style>