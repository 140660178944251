<template>
  <v-main>
    <div class="">
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>
      <v-container>

        <v-row dense class="mt-0 ">
          <v-col class="col-12">
            <Headline text="30× Persönlichkeitsentwicklung | 15 Minuten am Tag | 0 €!"></Headline>
          </v-col>
        </v-row>

        <v-row class="" dense>
          <v-col class="col-12 col-md-6">
            <div class="video-wrapper">
              <vimeo-player ref="player" video-id="684127946" :options="{ dnt: true }"></vimeo-player>
            </div>
          </v-col>

          <v-col class="col-12 col-md-6">
            <LandingPageLoginWidget></LandingPageLoginWidget>
          </v-col>

          <v-spacer></v-spacer>
          <v-col v-if="false" cols="3" style="background-color2: teal">

            <iframe
                src="https://outlook.office365.com/owa/calendar/RoySpitzner@mitNORM.com/bookings/s/zBEAjygQjECnVrPcLHUJWA2"
                width="100%"
                height="100%" style="border:0"></iframe>

          </v-col>
          <v-col v-if="false" class="col-12 col-md-2 col-xl-1" align-self="end">
            <NetworkLogos></NetworkLogos>
          </v-col>
        </v-row>

        <Headline text="30× Selbstführung" class="mt-8"></Headline>

        <v-card tile flat :color="$vuetify.theme.themes.light.c_bg_landingpage" class="mt-0">
          <v-card-text class="black--text landing-page-paragraph pt-0">
            <h3>Werde deine eigene Führungskraft und übernimm Verantwortung für alles Gute & Schlechte in deinem Leben.
              Schaffe Werte!</h3>
            <h3 class="mt-2">30-teiliger Kurs inklusive Umsetzungsaufgaben - 15 Min. am Tag - 0€</h3>
            <h3 class="mt-2" v-if="false">Als Life Coach helfe ich dir, dich in diesen Bereichen zu entwickeln:</h3>
          </v-card-text>
        </v-card>

        <Headline text="Wir navigieren - du setzt um!" class="mt-8"></Headline>

        <v-card tile flat :color="$vuetify.theme.themes.light.c_bg_landingpage" class="mt-0">
          <v-card-text class="black--text">
            <DemoCirclesContainer class="mb-4"></DemoCirclesContainer>
          </v-card-text>
        </v-card>

        <Headline text="Mein Netzwerk für deinen Erfolg" class="mt-8"></Headline>

        <NetworkPartners class="mt-4"></NetworkPartners>

        <v-card tile flat :color="$vuetify.theme.themes.light.c_bg_landingpage" class="mt-8">
          <v-card-text class="black--text">
            <h3 class="">Du willst von Anfang an eine intensive Betreuung über 3 Jahre?</h3>
            <h3 class="mt-1">Dann buch dir einen Termin um zu erfahren, wie du an diesem Coaching teilnehmen kannst.</h3>
            <div class="d-flex flex-column align-center mt-2">
              <div class="bullet-point">• wöchentliche Coachingtermine</div>
              <div class="bullet-point">• 5 Tage Mallorca-Abschlussreise am Ende des 1. Jahres</div>
              <div class="bullet-point">• 3 Jahre Premiummitgliedschaft beim Karriereinstitut</div>
              <div class="bullet-point">• Insights Persönlichkeitsanalyse</div>
            </div>
            <div class="d-flex justify-center mt-4 mb-3">
              <v-btn color="white" x-large href="https://outlook.office365.com/owa/calendar/RoySpitzner@mitNORM.com/bookings/"
                     target="_blank">
                Termin buchen
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-row class="my-8">
          <v-col>
            <NetworkLogosVertical class="d-block d-md-none"></NetworkLogosVertical>
            <NetworkLogos class="d-none d-md-block"></NetworkLogos>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>

<script>
import NetworkLogos from "@/components/NetworkLogos";
import NetworkLogosVertical from "@/components/NetworkLogosVertical";
import LandingPageLoginWidget from "@/components/LandingPageLoginWidget";
import DemoCirclesContainer from "@/components/landing_page/demo_circles/DemoCirclesContainer";
import NetworkPartners from "@/components/landing_page/NetworkPartners";
import Headline from "@/components/landing_page/Headline";

export default {
  name: "LandingPage",
  components: {
    LandingPageLoginWidget,
    NetworkLogosVertical,
    NetworkLogos,
    Headline,
    NetworkPartners,
    DemoCirclesContainer
  },
  data() {
    return {
      backgroundUrl: require("@/assets/images/climber_1080.jpg")
    };
  }
};
</script>

<style>

.video-wrapper embed,
.video-wrapper iframe,
.video-wrapper object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: pan-y !important;
}

</style>

<style scoped>
.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7427171552214635) 10%, rgba(0, 0, 0, 0) 55%);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  touch-action: pan-y !important;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}

/*
h2 {
  font-weight: normal;
  font-size: 16pt;
}

h3 {
  font-weight: normal;
  font-size: 18pt;
  line-height: 1.2;
}

h4 {
  font-weight: normal;
  font-size: 12pt;
}*/

/*
ul {
  list-style: "\2794 \0020 \0020";
}*/

.handwriting {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-size: 20pt;
  font-weight: 500;
}

h3 {
  font-weight: 400;
  text-align: center;
  font-size: min(5vw, 18pt);
  line-height: 1.25;
}

.bullet-point {
  font-size: min(4.5vw, 13pt);
  margin-top: 5px;
}

</style>