<template>
  <v-row dense>
    <v-col class="col-6 col-sm-3 col-md-6 col-lg-3">
      <SocialLoginGoogleButton></SocialLoginGoogleButton>
    </v-col>
    <v-col class="col-6 col-sm-3 col-md-6 col-lg-3">
      <SocialLoginFacebookButton></SocialLoginFacebookButton>
    </v-col>
    <v-col class="col-6 col-sm-3 col-md-6 col-lg-3">
      <SocialLoginMicrosoftButton></SocialLoginMicrosoftButton>
    </v-col>
    <v-col class="col-6 col-sm-3 col-md-6 col-lg-3">
      <SocialLoginAppleButton></SocialLoginAppleButton>
    </v-col>
  </v-row>
</template>

<script>
import SocialLoginAppleButton from "@/components/SocialLoginAppleButton";
import SocialLoginMicrosoftButton from "@/components/SocialLoginMicrosoftButton";
import SocialLoginFacebookButton from "@/components/SocialLoginFacebookButton";
import SocialLoginGoogleButton from "@/components/SocialLoginGoogleButton";

export default {
  name: "SocialLogin",
  components: { SocialLoginAppleButton, SocialLoginMicrosoftButton, SocialLoginFacebookButton, SocialLoginGoogleButton }
};
</script>

<style scoped>

</style>