<template>
    <!-- hide on scroll is too bugged -->
    <v-app-bar app :hide-on-scroll2="$vuetify.breakpoint.smAndDown" elevation="1" color="c_background"> <!-- omitted clipped_left -->

        <!-- left side -->
        <Logo></Logo>

        <div v-if="userIsLoggedIn" class="d-none d-sm-inline-flex align-baseline">

            <!-- home -->
            <v-btn :to="{ name: 'Home' }" class="text-none d-none d-sm-inline-flex ml-3 mr-3" text tile>
                <v-icon left>mdi-home-outline</v-icon>
                Home
            </v-btn>

            <!-- lessons -->
            <v-btn :to="{ name: 'Lessons' }" class="text-none d-none d-sm-inline-flex" text tile>
                <v-icon left>mdi-television-play</v-icon>
                Folge ansehen
            </v-btn>

            <!-- coaching -->
            <v-btn v-if="hasCoachingAccess" :to="{ name: 'Coaching' }" class="text-none d-none d-sm-inline-flex" text tile>
                <v-icon left>mdi-account-group-outline</v-icon>
                Coaching
            </v-btn>

        </div>

        <v-spacer></v-spacer>
        <!-- right side -->
        <div v-if="!userIsLoggedIn" class="d-none d-sm-inline-flex">

            <v-btn to="/login" class="text-none mr-1" text tile>
                <v-icon left>mdi-login</v-icon>
                Login
            </v-btn>

            <v-btn to="/signup" class="text-none mr-1" text tile>
                <v-icon left>mdi-account-plus-outline</v-icon>
                Registrieren
            </v-btn>

        </div>

        <!-- size helper -->
        <v-btn
            class="d-none d-sm-inline-flex" text tile v-if="!(helpersHidden || hideSizeHelper)" v-on:click="hideHelper">
            <span class="d-flex d-sm-none">XS</span>
            <span class="d-none d-sm-flex d-md-none">SM</span>
            <span class="d-none d-md-flex d-lg-none">MD</span>
            <span class="d-none d-lg-flex d-xl-none">LG</span>
            <span class="d-none d-xl-flex">XL</span>
        </v-btn>

        <v-btn v-if="userIsLoggedIn && roleInternalAccess" :to="{ name: 'InternalArea' }" class="text-none d-none d-md-inline-flex mr-3" text tile>
            <v-icon left>mdi-lock-outline</v-icon>
            Interner Bereich
        </v-btn>

        <!-- logout button with text -->
        <v-btn v-if="userIsLoggedIn" class="text-none d-none d-md-inline-flex" text tile
               :to="{ name: 'Login', params: { action: 'logout' } }">
            <v-icon left>mdi-logout</v-icon>
            <div>Abmelden</div>
        </v-btn>

        <!-- logout button icon only -->
        <v-btn v-if="userIsLoggedIn && false" class="d-none d-sm-inline-flex d-md-none" text tile
               :to="{ name: 'Login', params: { action: 'logout' } }">
            <v-icon small>mdi-logout</v-icon>
        </v-btn>

        <v-app-bar-nav-icon class="ml-1" @click="showNavigationDrawer"></v-app-bar-nav-icon>

    </v-app-bar>
</template>

<script>
import Logo from "@/components/Logo";

import mitt from "mitt";

window.mitt = window.mitt || new mitt();

export default {
    name: "AppBar",
    components: { Logo },
    data: () => ({
        hideSizeHelper: false
    }),
    computed: {
        helpersHidden: function() {
            return process.env.NODE_ENV !== "development";
        },
        userIsLoggedIn() {
            return this.$store.state.userIsLoggedIn;
        },
        roleInternalAccess() {
            return this.$store.state.roles.includes("ROLE_INTERNAL_ACCESS");
        },
        hasCoachingAccess() {
            return this.$store.state.roles.includes("ROLE_COACHING_ACCESS");
        }
    },
    methods: {
        showNavigationDrawer() {
            window.mitt.emit("showNavigationDrawer");
        },
        hideHelper: function() {
            this.hideSizeHelper = true;
        }
    }
};
</script>

<style scoped>

</style>