<template>
  <div>
    <div v-if="loading">
      <em>Wirtschaftliche Ziele werden geladen...</em>
    </div>
    <div v-else>
      <div v-if="!dataPresent">
        <div class="text-body-1 black--text">
          Bitte definiere deine wirtschaftlichen Ziele in der Folge <a href="/lessons/21">Meine Finanzen - Status
          Quo</a>, um diese Aufgabe freizuschalten.
        </div>
      </div>
      <div v-else>
        <div class="text-h6 black--text mb-1">Dein langfristiges Ziel</div>
        <v-sheet elevation="1" class="mb-2 px-3 py-3" rounded>
          <div class="d-flex flex-column flex-md-row align-md-center">
            <div class="d-flex flex-column">
              <div class="d-flex align-baseline">
                <div class="mr-1">Ziel:</div>
                <div class="text-body-1 black--text">{{ retirement["what"] | retirement }}</div>
              </div>
              <div class="d-flex align-baseline">
                <div class="mr-1">Sparrate:</div>
                <div class="text-body-1 black--text">{{ retirement["rate"] | floatToCurrency }}€ / Monat</div>
              </div>
            </div>
          </div>
        </v-sheet>

        <div class="text-h6 black--text mb-1 mt-4">Priorisiere deine kurz- und mittelfristigen Ziele</div>
        <div class="mb-1">Höchste Priorität</div>
        <transition-group name="component-list">
          <div v-for="(goal, index) in goals" :key="goal['what']">
            <v-sheet elevation="1" class="mb-2 px-3 py-3" rounded>
              <div class="d-flex flex-column flex-md-row align-md-center">
                <div class="d-flex flex-column">
                  <div class="d-flex align-baseline">
                    <div class="mr-1">Ziel:</div>
                    <div class="text-body-1 black--text">{{ goal["what"] }}</div>
                  </div>
                  <div class="d-flex align-baseline">
                    <div class="mr-1">Wie viel:</div>
                    <div class="text-body-1 black--text">{{ goal["cost"] | floatToCurrency }}€</div>
                  </div>
                  <div class="d-flex align-baseline">
                    <div class="mr-1">Wann:</div>
                    <div class="text-body-1 black--text">{{ timespans[goal["when"]-1].text }}</div>
                  </div>
                  <div class="d-flex align-baseline">
                    <div class="mr-1">Sparrate:</div>
                    <div class="text-body-1 black--text">{{ goal["rate"] | floatToCurrency }}€ / Monat</div>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex justify-end ml-2 mt-2 mt-md-0">
                  <v-btn small outlined class="mr-2" :disabled="index === 0" @click="moveGoal(index, true)">
                    <v-icon left>mdi-chevron-up</v-icon>
                    Hoch
                  </v-btn>
                  <v-btn small outlined :disabled="index === goals.length - 1" @click="moveGoal(index, false)">
                    <v-icon left>mdi-chevron-down</v-icon>
                    Runter
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </div>
        </transition-group>
        <div class="">Niedrigste Priorität</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinancialGoalsPriorityView",
  props: ["component", "filledOutComp", "onChange", "validationResult", "reference"],
  data: () => {
    return {
      loading: true,
      dataPresent: false,
      fofcDto: null,
      goals: [],
      retirement: {},
      timespans: [
        { value: "1", text: "in 1 Jahr" },
        { value: "2", text: "in 2 Jahren" },
        { value: "3", text: "in 3 Jahren" },
        { value: "4", text: "in 4 Jahren" },
        { value: "5", text: "in 5 Jahren" },
        { value: "6", text: "in 6 Jahren" },
        { value: "7", text: "in 7 Jahren" },
        { value: "8", text: "in 8 Jahren" },
        { value: "9", text: "in 9 Jahren" },
        { value: "10", text: "in 10 Jahren" },
        { value: "11", text: "in 11 Jahren" },
        { value: "12", text: "in 12 Jahren" },
        { value: "13", text: "in 13 Jahren" },
        { value: "14", text: "in 14 Jahren" },
        { value: "15", text: "in 15 Jahren" }
      ],
    };
  },
  computed: {},
  filters: {
    floatToCurrency: function(value) {
      const rounded = Math.round(value * 100) / 100;
      return (rounded + "").replace(".", ",");
    },
    retirement: function(value) {
      if (value === "_RETIREMENT_") {
        return "Altersvorsorge";
      }
      return value;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch("getRequest", ["forms/fofc/" + this.component["data"]["financialGoalsId"] + "/" + this.reference, this.fetchDataCallback]);
    },
    fetchDataCallback(data) {
      if (data["data"]) {
        this.fofcDto = data;
        const goals = data["data"]["financialGoals"];
        for (let goal of goals) {
          if (goal["what"]) {
            this.goals.push(goal);
          }
        }
        //this.goals.push(data["data"]["retirement"]);
        this.sortGoals();
        this.retirement = data["data"]["retirement"];
        this.dataPresent = true;
      } else {
        this.dataPresent = false;
      }
      this.loading = false;
    },
    sortGoals() {
      this.goals.sort(this.compare);
    },
    compare(a, b) {
      if ( a['priority'] < b['priority'] ){
        return -1;
      }
      if ( a['priority'] > b['priority'] ){
        return 1;
      }
      return 0;
    },
    moveGoal(index, moveUp) {
      const goal = this.goals[index];
      const offset = moveUp ? -1 : 1;
      const otherGoal = this.goals[index + offset];
      const priority1 = goal['priority'];
      goal['priority'] = otherGoal['priority'];
      otherGoal['priority'] = priority1;
      this.sortGoals();
      this.pushChanges();
    },
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    },
    pushChanges() {
      const updates = [];
      updates.push(this.fofcDto);
      this.$store.dispatch("putRequest", ["forms/fill-out", updates]);
    }
  }
};
</script>

<style scoped>
.component-list-move {
  transition: transform 0.3s;
}

.component-list-enter-active,
.component-list-leave-active {
  transition: all 0.5s ease;
}

.component-list-enter,
.component-list-leave-to {
  opacity: 0;
}
</style>