<template>
  <v-card :disabled="disabled" :style="disabled ? 'filter: saturate(0)' : ''">
    <v-card-text class="pa-3">

      <router-link :to="routerLink" class="text-decoration-none">

        <div class="d-flex d-sm-none flex-column">

          <div class="d-flex">

            <div style="min-width: 150px; min-height: 84px; position: relative">
              <v-img :src="lesson['thumbnailUrl']"
                     max-width="150"></v-img>
              <div class="thumbnail-overlay thumbnail-duration" style="">
                <div class="d-flex align-center" style="line-height: 18px">
                  <!-- <v-icon class="mr-1" small color="white">mdi-clock-outline</v-icon> -->
                  {{ duration }}
                </div>
              </div>
            </div>

            <div class="d-flex flex-column ml-2" style="overflow:hidden;">
              <div class="text-body-2 grey--text text--darken-1" style="height: 16px">Folge {{ lessonIndex }}</div>
              <div class="text-body-1 text-sm-h6 black--text mt-1"
                   style="line-height: 21px; max-height: 63px;"
                   v-text="lesson['title']"
              ></div>
            </div>

          </div>

          <div class="d-flex mt-2">
            <v-chip v-if="lessonProgress && lessonProgress['videoWatched']" label small outlined color="green darken-1" class="mr-2">Angesehen</v-chip>
            <v-chip v-if="!lessonProgress || !lessonProgress['videoWatched']" label small outlined color="grey darken-2" class="mr-2">Nicht angesehen</v-chip>
            <v-chip v-else-if="!lessonProgress['formCompleted']" label small outlined color="red darken-2" class="mr-2">Offene Aufgaben</v-chip>
            <v-chip v-if="lessonProgress && lessonProgress['formCompleted']" label small outlined color="green darken-1" class="">Aufgaben erledigt</v-chip>
          </div>

        </div>

        <div class="d-none d-sm-flex">

          <div style="width: 150px; min-height: 84px; position: relative">
            <v-img :src="lesson['thumbnailUrl']"
                   max-width="150"></v-img>
            <div class="thumbnail-overlay thumbnail-duration" style="">
              <div class="d-flex align-center" style="line-height: 18px">
                <!-- <v-icon class="mr-1" small color="white">mdi-clock-outline</v-icon> -->
                {{ duration }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-column ml-3" style="overflow: hidden">

            <div class="text-body-2 grey--text text--darken-1" style="height: 16px">Folge {{ lessonIndex }}</div>

            <div class="text-h6 black--text mt-1"
                 style="line-height: 24px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis"
                 v-text="lesson['title']"
            ></div>

            <v-spacer></v-spacer>

            <div class="d-flex mt-0">
              <v-chip v-if="lessonProgress && lessonProgress['videoWatched']" label small outlined color="green darken-1" class="mr-2">Angesehen</v-chip>
              <v-chip v-if="!lessonProgress || !lessonProgress['videoWatched']" label small outlined color="grey darken-2" class="mr-2">Nicht angesehen</v-chip>
              <v-chip v-else-if="!lessonProgress['formCompleted']" label small outlined color="red darken-2" class="mr-2">Offene Aufgaben</v-chip>
              <v-chip v-if="lessonProgress && lessonProgress['formCompleted']" label small outlined color="green darken-1" class="">Aufgaben erledigt</v-chip>
            </div>

          </div>

          <v-spacer></v-spacer>

          <v-btn class="d-none d-md-block align-self-center mr-4" :color="color || 'primary'" dark outlined>
            <v-icon left class="">mdi-play</v-icon>
            Ansehen
          </v-btn>

        </div>

      </router-link>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "LessonSummary",
  props: ["lesson", "lessonProgress", "lessonIndex", "disabled", "color"],
  computed: {
    routerLink() {
      return { path: "lessons/" + this.lesson.id };
    },
    duration() {
      let seconds = this.lesson["duration"];
      return Math.trunc(seconds / 60) + ":" + String(seconds % 60).padStart(2, "0");
    }
  }
};
</script>

<style scoped>

.thumbnail-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 2px 5px;
  margin: 4px;
  border-radius: 2px;
}

.thumbnail-episode {
  top: 0;
  right: 0;
  /*left: 0;
  bottom: 0;*/
}

.thumbnail-duration {
  right: 0;
  bottom: 0;
}

</style>