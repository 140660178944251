<template>
  <div>
    <v-main>
      <v-container class="mt-md-4 mb-16 px-0 px-sm-3">
        <HomeMainPanel></HomeMainPanel>
        <ProgressCircleCard class="mt-4 mt-md-8" :course-progress="courseProgress"></ProgressCircleCard>
        <div v-for="course in courses" :key="course.id" class="mt-4 mt-sm-8">
          <div class="text-h6 text-sm-h5 ml-2 ml-sm-0">{{ course.name }}</div>
          <div v-for="(lessonDto, i) in course['lessons']" :key="lessonDto.lesson.id">
            <LessonSummary
                class="mt-1 mt-sm-3"
                :lesson="lessonDto.lesson" :lesson-progress="lessonDto['lessonProgress']" :lesson-index="i + 1"
                :disabled="!firstLessonFinished && lessonDto.lesson.id > 1" :color="course['color']"
            ></LessonSummary>
            <div v-if="!firstLessonFinished && lessonDto.lesson.id === 1" class="mt-2 mb-6 grey--text text--darken-2 mx-2 mx-sm-0">
              Alle weiteren Inhalte werden nach Abschluss dieser Folge freigeschaltet
            </div>
          </div>
        </div>

      </v-container>
    </v-main>
  </div>
</template>

<script>
import MainImageHeader from "@/components/MainImageHeader";
import LessonSummary from "@/components/LessonSummary";
import ProgressCircleCard from "@/components/ProgressCircleCard";
import HomeMainPanel from "@/components/HomeMainPanel";

export default {
  name: "Home",
  components: {
    HomeMainPanel,
    ProgressCircleCard, MainImageHeader, LessonSummary
  },
  data: () => ({
    courses: [],
    firstLessonFinished: true,
    courseProgress: []
  }),
  computed: {},
  mounted() {
    this.$store.dispatch("getRequest", ["home", this.getHomeDataCallback]);
  },
  methods: {
    getHomeDataCallback(data) {
      this.courses = data["courses"];
      this.firstLessonFinished = data["firstLessonFinished"];
      for (let i = 0; i < 6; i++) {
        let course = this.courses[i];
        if (course) {
          this.courseProgress.push(course["progress"]);
        } else {
          this.courseProgress.push(0);
        }
      }

      this.$store.state.hasCoachingAccess = data["hasCoachingAccess"];
    }
  }
};
</script>

<style scoped>
@media (max-width: 600px) {
  .v-main {
    padding-bottom: 48px !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>