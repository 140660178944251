<template>
  <div>
    <v-checkbox v-for="(option, index) in component.data.options" :key="option.text"
                v-model="filledOutComp['data']['selections']" :value="index" :label="option.text"
                hide-details="auto" class="ma-0" :class="{'mt-2': index > 0}" @change="change"
                :success="success" :error="error"
                :success-messages="index === component.data.options.length -1 ? successMessage : ''"
                :error-messages="index === component.data.options.length -1 ? errorMessage : ''"></v-checkbox>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "MultipleChoiceView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    successMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "CORRECT") {
          return "Korrekt"
        }
      }
      return "";
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Inkorrekte Auswahl"
        }
      }
      return "";
    },
    success() {
      return this.successMessage !== "";
    },
    error() {
      return this.errorMessage !== "";
    }
  },
  mounted() {
    if (!this.filledOutComp["data"]["selections"]) {
      Vue.set(this.filledOutComp["data"], "selections", []);
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>