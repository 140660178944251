<template>
  <v-bottom-navigation
      class="d-flex d-sm-none"
      fixed
      app
      grow
      dense
      background-color="#F8F8F8"
      color="primary"
      :hide-on-scroll2="$vuetify.breakpoint.smAndDown"
  >

    <v-btn v-if="!userIsLoggedIn" :to="{ name: 'SignUp' }">
      <span>Registrieren</span>
      <v-icon>mdi-account-plus-outline</v-icon>
    </v-btn>

    <v-btn v-if="!userIsLoggedIn" :to="{ name: 'Login' }">
      <span>Login</span>
      <v-icon>mdi-login</v-icon>
    </v-btn>

    <v-btn v-if="userIsLoggedIn" :to="{ name: 'Home' }">
      <span>Home</span>
      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <v-btn v-if="userIsLoggedIn" :to="{ name: 'Lessons' }">
      <span>Folge ansehen</span>
      <v-icon>mdi-television-play</v-icon>
    </v-btn>

    <v-btn v-if="userIsLoggedIn && roleInternalAccess" :to="{ name: 'InternalArea' }">
      <div>Intern</div>
      <v-icon>mdi-lock-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "AppBottomNavigation",
  computed: {
    userIsLoggedIn() {
      return this.$store.state.userIsLoggedIn;
    },
    roleInternalAccess() {
      return this.$store.state.roles.includes("ROLE_INTERNAL_ACCESS");
    }
  }
};
</script>

<style scoped>

</style>