import { render, staticRenderFns } from "./SocialLoginMicrosoftButton.vue?vue&type=template&id=c28352de&scoped=true&"
import script from "./SocialLoginMicrosoftButton.vue?vue&type=script&lang=js&"
export * from "./SocialLoginMicrosoftButton.vue?vue&type=script&lang=js&"
import style0 from "./SocialLoginMicrosoftButton.vue?vue&type=style&index=0&id=c28352de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c28352de",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VImg,VProgressCircular})
