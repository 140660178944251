import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3C9B9C",
        c_dontknow: "#2A6D6D",
        c_writing: "#1a3636",
        background: "#F8F8F8",
        c_background: "#F8F8F8",
        c_bg_landingpage: "rgba(248,248,248,0.87)"
      }
    }
  },
  defaultAssets: false
});
