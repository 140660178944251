<template>
  <v-main>
    <div class="fill-height">
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>

      <v-container class="fill-height">
        <div class="pa-0 col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4" style="margin: auto;">

          <v-card v-if="cookieLoginInProgress" color="c_background">
            <v-card-title>
              <div class="d-flex flex-wrap justify-center flex-fill align-center">
                <div class="text-h5 mr-4 my-2">Automatische Anmeldung</div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-card-title>
          </v-card>

          <div v-else-if="accountNotConfirmed">
            <v-card color="c_background">
              <v-card-title>Account bestätigen</v-card-title>
              <v-card-text>
                <div class="black--text text-body-1 my-">Wir haben eine E-Mail an <strong>{{ email }}</strong>
                  geschickt. Bitte klicke auf den Link in der E-Mail um deine Registrierung abzuschließen.
                  Klicke danach auf "Einloggen".
                </div>
                <div class="mt-4">
                  <div class="mr-2 my-1">Keine E-Mail erhalten? Sieh zuerst in deinem Spam-Ordner nach und fordere
                    danach eine neue E-Mail an:
                  </div>
                  <div class="d-flex flex-wrap align-center">
                    <v-btn outlined small class="mr-2 mt-2" :disabled="resendInProgress" @click="sendActivationMail">
                      Erneut Senden
                    </v-btn>
                    <div class="d-flex flex-grow-1 justify-end">
                      <v-btn color="primary" small class="mt-2" :loading="checkAccountConfirmedInProgress"
                             @click="checkIfAccountIsConfirmed">Einloggen</v-btn>
                    </div>
                  </div>
                  <div v-if="accountStillNotConfirmed">
                    <div class="red--text text-body-1 mt-2 text-center">
                      Du hast deinen Account noch nicht bestätigt.<br>Bitte klicke auf den Link in der E-Mail.
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>
            <v-card v-if="forgotPassword" color="c_background">
              <v-card-title>
                Passwort vergessen
              </v-card-title>
              <v-card-text>
                <div>Trag deine E-Mail-Adresse ein und wir schicken dir einen Link zu, mit dem du dein Passwort
                  zurücksetzen kannst.
                </div>
                <v-text-field label="E-Mail-Adresse" v-model="email" type="email" hide-details
                              :disabled="forgotPasswordInProgress"></v-text-field>
                <div v-if="forgotPasswordErrorText" class="error--text mt-2">{{ forgotPasswordErrorText }}</div>
                <div v-if="forgotPasswordSuccess" class="success--text mt-2">
                  E-Mail versendet. Falls du keine E-Mail erhalten hast, sieh zuerst in deinem Spam-Ordner nach und
                  fordere danach eine neue E-Mail an.
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn text class="text-none" @click="forgotPassword = false">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="forgotPasswordInProgress" @click="forgotPasswordSubmit">
                  Absenden
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-else color="c_background" style="width: 100%">
              <v-card-title style="word-break: initial">{{ headerText }}</v-card-title>
              <v-card-text class="">
                <v-form v-model="valid" ref="form" @keyup.native.enter="submit">

                  <v-text-field label="E-Mail-Adresse" v-model="email" type="email"
                                :rules="emailRules" :disabled="loginInProgress"></v-text-field>
                  <v-text-field label="Passwort" v-model="password" type="password"
                                :rules="passwordRules" :disabled="loginInProgress"></v-text-field>

                  <div class="d-flex align-center">
                    <div v-if="loginFailed" class="error--text mr-2">{{ errorText }}</div>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" :disabled="loginInProgress" color="primary">Login</v-btn>
                  </div>

                  <v-progress-linear v-if="loginInProgress" indeterminate color="primary"
                                     class="mt-3"></v-progress-linear>

                </v-form>

                <div class="d-flex align-baseline justify-end mt-2">
                  <v-btn text class="text-none" @click="forgotPasswordClicked">Passwort vergessen?</v-btn>
                </div>

                Oder anmelden mit:
                <SocialLogin></SocialLogin>

              </v-card-text>
            </v-card>

            <v-card v-if="!credentials" class="mt-3" color="c_background" style="width: 100%">
              <v-card-title class="py-3">
                <div class="d-flex flex-wrap text-h5" style="font-size: 14pt !important; font-weight: 300">
                  <div class="black--text mr-2">Neu hier?</div>
                  <router-link to="/signup" class="">Jetzt Account erstellen!</router-link>
                </div>
              </v-card-title>
            </v-card>
          </div>

        </div>
      </v-container>

    </div>

  </v-main>
</template>

<script>
import PasswordChecker from "@/components/PasswordChecker";
import SocialLogin from "@/components/SocialLogin";
import firebase from "firebase/compat";
import axios from "axios";

export default {
  name: "Login",
  props: ["action", "credentials"],
  components: { SocialLogin, PasswordChecker },
  data() {
    return {
      backgroundUrl: require("@/assets/images/climber_1080.jpg"),
      email: "",
      password: "",
      valid: false,
      cookieLoginInProgress: false,
      loginInProgress: false,
      loginFailed: false,
      errorText: "",
      emailRules: [],
      passwordRules: [],
      accountNotConfirmed: false,
      resendInProgress: false,
      resendSuccess: false,
      checkAccountConfirmedInProgress: false,
      accountStillNotConfirmed: false,
      forgotPassword: false,
      forgotPasswordInProgress: false,
      forgotPasswordErrorText: "",
      forgotPasswordSuccess: false
    };
  },
  computed: {
    headerText() {
      if (this.action === "logout") {
        return "Erfolgreich abgemeldet";
      }
      let param = this.$route["query"]["param"];
      if (param === "activated") {
        return "Dein Account wurde erfolgreich aktiviert. Nutze deine Anmeldedaten, um dich einzuloggen.";
      }

      return "Anmelden";
    },
    userIsLoggedIn() {
      return this.$store.getters.userIsLoggedIn;
    }
  },
  mounted() {
    if (this.credentials) {
      this.email = this.credentials.email;
      this.password = this.credentials.password;
      this.submit();
    } else {
      if (this.action === "logout") {
        this.$cookies.remove("roy-spitzner-custom-token");
        axios.defaults.headers.common["Authorization"] = null;
      }
      this.cookieLoginInProgress = true;
      this.$store.dispatch("attemptLoginWithCookieNew", this.cookieLoginCallback);
    }
  },
  methods: {
    submit() {
      // set validation rules
      this.emailRules = [
        v => !!v || "E-Mail Adresse eingeben",
        v => /.+@.+\..+/.test(v) || "E-Mail Adresse ist ungültig"
      ];
      this.passwordRules = [
        v => !!v || "Passwort eingeben"
      ];

      // let the DOM update
      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.loginInProgress = true;

          const email = this.email;
          const password = this.password;

          firebase.auth().signInWithEmailAndPassword(email, password).then(firebaseResponse => {
            this.loginFailed = false;
            this.loginInProgress = false;
            let emailVerified = firebaseResponse["user"]["_delegate"]["emailVerified"];
            if (!emailVerified) {
              this.accountNotConfirmed = true;
              this.sendActivationMail();
            } else {
              this.$store.dispatch("processFirebaseResponse", [firebaseResponse, this.loginCallback]);
            }
          }).catch(reason => {
            this.loginFailed = true;
            this.loginInProgress = false;
            let code = reason.code;
            if (code === "auth/user-not-found") {
              this.errorText = "Es existiert kein Account mit dieser E-Mail-Adresse";
            } else if (code === "auth/wrong-password") {
              this.errorText = "Das Passwort ist nicht korrekt";
            } else {
              this.errorText = "Es gab einen Fehler bei der Anmeldung: " + code;
            }
          })
        }
      });
    },
    cookieLoginCallback(success) {
      if (success) {
        this.pushRoute();
      } else {
        this.cookieLoginInProgress = false;
      }
    },
    loginCallback(data) {
      this.loginInProgress = false;
      if (data.success) {
        this.pushRoute();
      } else {
        if (data["accountNotConfirmed"]) {
          this.accountNotConfirmed = true;
        } else {
          this.errorText = data.errorMessage;
          this.loginFailed = true;
        }
      }
    },
    pushRoute() {
      let routeAfterLogin = this.$store.state.routeAfterLogin;
      if (routeAfterLogin === "") {
        this.$router.push("/home");
      } else {
        this.$router.push(routeAfterLogin);
      }
    },
    sendActivationMail() {
      this.resendInProgress = true;
      setTimeout(() => {
        // 20 second cooldown
        this.resendInProgress = false;
      }, 20000);
      let currentUser = firebase.auth().currentUser;
      if (currentUser) {
        firebase.auth().currentUser.sendEmailVerification()
      }
    },
    checkIfAccountIsConfirmed() {
      this.checkAccountConfirmedInProgress = true;
      this.accountStillNotConfirmed = false;
      setTimeout(() => {
        this.checkAccountConfirmedInProgress = false;
        this.accountStillNotConfirmed = true;
      }, 3000);
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(firebaseResponse => {
        let emailVerified = firebaseResponse["user"]["_delegate"]["emailVerified"];
        if (emailVerified) {
          this.$store.dispatch("processFirebaseResponse", [firebaseResponse, this.loginCallback]);
        }
      }).catch(reason => {

      })
    },
    forgotPasswordClicked() {
      this.forgotPassword = true;
      this.forgotPasswordErrorText = "";
      this.forgotPasswordInProgress = false;
      this.forgotPasswordSuccess = false;
    },
    forgotPasswordSubmit() {
      if (!this.email) {
        this.forgotPasswordErrorText = "Bitte trag deine E-Mail-Adresse ein";
      } else {
        this.forgotPasswordInProgress = true;
        firebase.auth().sendPasswordResetEmail(this.email).then(value => {
          this.forgotPasswordErrorText = "";
          this.forgotPasswordSuccess = true;
          this.forgotPasswordInProgress = false;
        }).catch(reason => {
          this.forgotPasswordInProgress = false;
          let code = reason.code;
          if (code === "auth/user-not-found") {
            this.forgotPasswordErrorText = "Es existiert kein Account mit dieser E-Mail-Adresse";
          } else {
            this.forgotPasswordErrorText = "Es gab einen Fehler: " + code;
          }
        })
      }
    }
  }
};
</script>

<style scoped>

.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 10%, rgba(0, 0, 0, 0) 55%);
}

</style>
