<template>
  <router-link :to="linkTarget" class="text-decoration-none d-flex align-center" >

    <div>
      <v-img src="@/assets/images/logo/Guidance_Coaching_Path.svg" width="135" height="42" contain></v-img>
    </div>

  </router-link>
</template>

<script>
export default {
  name: "Logo",
  computed: {
    primaryColor() {
      return this.$vuetify.theme.themes.light.logo;
    },
    iconColor() {
      return "#000";
    },
    textColor() {
      return "#000";
    },
    linkTarget() {
      return this.$store.state.userIsLoggedIn ? "/home" : "/";
    }
  }
};
</script>

<style scoped>
.icon-text {
  line-height: 1.05;
  font-size: 16pt;
  font-weight: 400;
}
.financial-guidance-text {
  line-height: 1;
  font-size: 16pt;
  font-weight: 300;
}
.handwritten-text {
  font-family: "Caveat", "Ink Free", cursive;
  line-height: 1.1;
  font-size: 15pt;
  font-weight: 600;
}
</style>