<template>
  <div>

    <div v-for='(line, index) in filledOutComp["data"]["lines"]' :key="index" class="mb-3">
      <div class="d-flex align-center">

        <div class="mr-2">{{ (index + 1) }}.</div>
        <v-text-field v-model='filledOutComp["data"]["lines"][index]' hide-details dense outlined class="mr-1" @input="change"></v-text-field>
        <v-btn v-if="!lineCountLocked" color="red" text icon tabindex="-1" @click="removeOption(index)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>

      </div>
    </div>

    <v-btn v-if="!lineCountLocked" class="ml-5" outlined small @click="addOption">
      <v-icon left>mdi-plus</v-icon>
      Textfeld hinzufügen
    </v-btn>

  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "TextFieldListView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    lines() {
      return this.filledOutComp["data"]["lines"];
    },
    lineCountLocked() {
      return this.component["data"]["lockLineCount"];
    }
  },
  mounted() {
    let lines = [];
    if (this.filledOutComp["data"]["lines"]) {
      lines = this.filledOutComp["data"]["lines"];
    }
    let linesLeft = this.component["data"]["lineCount"] - lines.length;
    while (linesLeft > 0) {
      lines.push("");
      linesLeft--;
    }
    Vue.set(this.filledOutComp["data"], "lines", lines);
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    },
    removeOption(index) {
      this.lines.splice(index, 1);
      this.change();
    },
    addOption() {
      this.lines.push("");
      this.change();
    }
  }
};
</script>

<style scoped>

</style>
