<template>
  <div class="d-none justify-center custom-social-login-container custom-social-login-disabled" @click="login">
    <div class="custom-social-login-logo">
      <v-img src="@/assets/images/social_login/Apple_logo.svg" max-width="14" contain></v-img>
    </div>
    <div style="width: 8px"></div>
    <div v-if="!loginInProgress" class="custom-social-login-text">Apple</div>
    <div v-else class="d-flex align-center">
      <v-progress-circular indeterminate color="white" size="25" width="3"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";

export default {
  name: "SocialLoginAppleButton",
  data() {
    return {
      loginInProgress: false
    };
  },
  methods: {
    login()  {
      this.loginInProgress = true;

    }
  }
};
</script>

<style scoped>
.custom-social-login-container {
  height: 42px;
  background-color: black;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  border-radius: 2px;
  cursor: pointer;
}

.custom-social-login-logo {
  padding-top: 12px;
}

.custom-social-login-text {
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  font-size: 16px;
  line-height: 42px;
  font-weight: 600;
  color: white;
}

.custom-social-login-disabled {
  pointer-events: none;
  opacity: 0.25;
}
</style>