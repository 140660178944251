import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import vueVimeoPlayer from "vue-vimeo-player";
import VueQuillEditor from 'vue-quill-editor';
import VueCookies from 'vue-cookies';
import VSnackbarQueue from '@tozd/vue-snackbar-queue';
import firebase from "firebase/compat";
import Chat from 'vue-beautiful-chat';
//import * as firebase from "firebase/app";
//import 'firebase/auth';

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"

import "@fontsource/caveat/400.css"
import "@fontsource/caveat/500.css"
import "@fontsource/caveat/600.css"
import "@fontsource/caveat/700.css"


Vue.config.productionTip = false;
Vue.use(vueVimeoPlayer);
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueCookies);
Vue.use(VSnackbarQueue);
Vue.use(Chat);

const axiosConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL
}

Vue.prototype.$http = axios.create(axiosConfig);

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
