<template>
  <div>
    <div v-if="element" class="d-flex justify-center" style="overflow: hidden;">
      <a :href="element.link" target="_blank">
        <v-img :src="element['imageLink']" max-width="200" max-height="200" class="elevation-4" contain></v-img>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecommendedReading",
  data() {
    return {
      element: null
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch("getRequest", ["recommended-reading", this.fetchDataCallback]);
    },
    fetchDataCallback(data) {
      if (data) {
        this.element = data;
      }
    }
  }
};
</script>

<style scoped>

</style>