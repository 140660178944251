<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text class="black--text text-body-1">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="type === 'delete'" color="red darken-1" text @click="confirm">
          Löschen
        </v-btn>
        <v-btn color="blue darken-1" text @click="cancel">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data: () => ({
    dialog: false,
    id: "",
    title: "",
    text: "",
    type: ""
  }),
  created() {
    window.mitt.on("showConfirmDialog", (details) => {
      this.id = details.id;
      this.title = details.title;
      this.text = details.text;
      this.type = details.type;
      this.dialog = true;
    });
  },
  methods: {
    cancel() {
      this.dialog = false;
      window.mitt.emit("confirmDialogResult", {
        id: this.id,
        result: false
      });
    },
    confirm() {
      this.dialog = false;
      window.mitt.emit("confirmDialogResult", {
        id: this.id,
        result: true
      });
    }
  }
};
</script>

<style scoped>

</style>