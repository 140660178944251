<template>
  <div class="d-flex custom-social-login-container" @click="login">
    <div class="custom-social-login-logo d-flex">
      <v-img src="@/assets/images/social_login/Microsoft_logo.svg" max-width="20" contain></v-img>
    </div>
    <div v-if="!loginInProgress" class="custom-social-login-text">Microsoft</div>
    <div v-else class="d-flex align-center justify-center flex-grow-1">
      <v-progress-circular indeterminate color="#5E5E5E" size="25" width="3"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";

export default {
  name: "SocialLoginMicrosoftButton",
  data() {
    return {
      loginInProgress: false
    };
  },
  methods: {
    login() {
      this.loginInProgress = true;

      const provider = new firebase.auth.OAuthProvider("microsoft.com");
      firebase.auth().signInWithPopup(provider).then(firebaseResponse => {
        this.$store.dispatch("processFirebaseResponse", [firebaseResponse, this.loginCallback]);
      }).catch(() => {
        this.loginInProgress = false;
        this.$store.dispatch("showError", "Anmeldung fehlgeschlagen oder abgebrochen");
      });

    },
    loginCallback(data) {
      this.loginInProgress = false;
      if (data.success) {
        let routeAfterLogin = this.$store.state.routeAfterLogin;
        if (routeAfterLogin === "") {
          this.$router.push("/home");
        } else {
          this.$router.push(routeAfterLogin);
        }
      }
    }
  }
};
</script>

<style scoped>
.custom-social-login-container {
  height: 42px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  border-radius: 2px;
  cursor: pointer;
}

.custom-social-login-logo {
  padding-left: 11px;
}

.custom-social-login-text {
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  line-height: 42px;
  font-weight: bold;
  color: #5E5E5E;
  flex-grow: 1;
  text-align: center;
}
</style>