<template>
  <v-navigation-drawer v-model="showDrawer" app temporary right>

    <v-list-item>
      <v-list-item-content>
        <Logo></Logo>
      </v-list-item-content>
    </v-list-item>

    <v-list v-if="userIsLoggedIn">

      <v-list-item :to="{ name: 'Home' }" link>
        <v-list-item-icon>
          <v-icon color="black">mdi-home-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/lessons" link>
        <v-list-item-icon>
          <v-icon color="black">mdi-television-play</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Folge ansehen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="roleInternalAccess" :to="{ name: 'InternalArea' }" link>
        <v-list-item-icon>
          <v-icon color="black">mdi-lock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Interner Bereich</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/literaturempfehlungen" link v-if="false">
        <v-list-item-icon>
          <v-icon color="black">mdi-book-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Literaturempfehlungen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'Login', params: { action: 'logout' } }">
        <v-list-item-icon>
          <v-icon color="black">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-list v-else>

      <v-list-item :to="{ name: 'SignUp' }" link>
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Registrieren</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'Login' }" link>
        <v-list-item-icon>
          <v-icon color="black">mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-list>

      <v-list-item :to="{ name: 'AboutMe' }" link>
        <v-list-item-icon>
          <v-icon v-if="false" color="black">mdi-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Über Mich</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to2="{ name: 'Network' }" href="https://www.financialguidance.de/" target="_blank" link>
        <v-list-item-icon>
          <v-icon v-if="false" color="black">mdi-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mein Netzwerk</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'Impressum' }" link>
        <v-list-item-icon>
          <v-icon v-if="false" color="black">mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Impressum</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'PrivacyPolicy' }" link>
        <v-list-item-icon>
          <v-icon v-if="false" color="black">mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Datenschutzerklärung</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'CustomerInformation' }" link>
        <v-list-item-icon>
          <v-icon v-if="false" color="black">mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Kundeninformation</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

  </v-navigation-drawer>
</template>

<script>
import Logo from "@/components/Logo";
import mitt from "mitt";
window.mitt = window.mitt || new mitt();
export default {
  name: "AppNavigationDrawer",
  components: { Logo },
  data() {
    return {
      showDrawer: false,
    };
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.state.userIsLoggedIn;
    },
    roleInternalAccess() {
      return this.$store.state.roles.includes("ROLE_INTERNAL_ACCESS");
    }
  },
  mounted() {
    window.mitt.on("showNavigationDrawer", () => {
      this.showDrawer = true;
    });
  }
};
</script>

<style scoped>

</style>