<template>
  <div :class="styleClasses + ' ' + margin">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "TextView",
  props: ["component", "isFirstItem"],
  data: () => {
    return {};
  },
  computed: {
    content() {
      return this.component.data.content;
    },
    style() {
      return this.component.data.style;
    },
    styleClasses() {
      switch (this.style) {
        case "task":
          return "text-h6 black--text mb-n22";
        case "default":
          return "text-body-1 black--text";
        default:
          return "";
      }
    },
    margin() {
      if (this.style === "task" && !this.isFirstItem) {
        return "mt-5";
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>

</style>