<template>
  <div class="mt-2">
    <div class="">Passwortanforderungen:</div>
    <div class="d-flex flex-column">
      <div v-for="rule in rules" :key="rule['label']" class="d-flex align-center">
        <div v-show="rule.value" v-text="'✔ ' + rule['label']" class="green--text"></div>
        <div v-show="!rule.value" v-text="'✘ ' + rule['label']" :class="password.length > 0 ? 'red--text' : ''"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordChecker",
  props: ["password", "update"],
  data() {
    return {
      rules: {
        longEnough: {
          label: "mind. 8 Zeichen",
          value: false
        },
        hasLowercase: {
          label: "Kleinbuchstabe",
          value: false
        },
        hasUppercase: {
          label: "Großbuchstabe",
          value: false
        },
        hasNumber: {
          label: "Ziffer",
          value: false
        },
        hasSymbol: {
          label: "Sonderzeichen",
          value: false
        }
      },
      valid: false
    };
  },
  watch: {
    password() {
      this.check();
      if (this.update) {
        this.update(this.valid);
      }
    }
  },
  methods: {
    check() {
      const pw = this.password;
      const rules = this.rules;

      rules["longEnough"].value = pw.length >= 8 && pw.length <= 10000;

      rules['hasLowercase'].value = false;
      rules['hasUppercase'].value = false;
      rules['hasNumber'].value = false;
      rules['hasSymbol'].value = false;

      for (let i = 0; i < pw.length; i++) {
        const c = pw.charAt(i);
        if (c >= 'a' && c <= 'z') {
          rules['hasLowercase'].value = true;
        }
        if (c >= 'A' && c <= 'Z') {
          rules['hasUppercase'].value = true;
        }
        if (c >= '0' && c <= '9') {
          rules['hasNumber'].value = true;
        }
        if (c >= ' ' && c <= '/' || c >= ':' && c <= '@' || c >= '[' && c <= '_' || c >= '{') {
          rules['hasSymbol'].value = true;
        }
      }

      this.valid = rules["longEnough"].value && rules['hasLowercase'].value && rules['hasUppercase'].value && rules['hasNumber'].value && rules['hasSymbol'].value;
    }
  }
};
</script>

<style scoped>

</style>