<template>
  <div>
    <v-text-field outlined v-model="data.content" hide-details="auto" :label="component['data']['label']"
                  @input="change" :success-messages="successMessage"
                  :error-messages="errorMessage" dense></v-text-field>
  </div>
</template>

<script>
export default {
  name: "DatePickerView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {
      menuOpen: false
    };
  },
  computed: {
    data() {
      return this.filledOutComp.data;
    },
    successMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "CORRECT") {
          return "Korrekt"
        }
      }
      return "";
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Ungültiges Datum - bitte Format TT.MM.JJJJ beachten, z.B. 03.07.1994"
        }
        if (this.validationResult.status === "EMPTY") {
          return "Bitte ausfüllen"
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style>

</style>