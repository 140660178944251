<template>
  <div>
    <!--
    <v-snackbar v-model="showSnackbar" centered timeout="-1" color="red lighten-3" light vertical>
      <div class="d-flex justify-center">Bitte E-Mail-Adresse bestätigen</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
        >
          Jetzt bestätigen
        </v-btn>
      </template>
    </v-snackbar>
    -->
    <v-bottom-navigation v-if="false" fixed app background-color="red lighten-3">
      <div class="flex-grow-1 d-flex align-center justify-center">
        <div class="ml-2">Bitte bestätige deine E-Mail-Adresse</div>
        <v-btn class="confirm-button ml-2" color="red lighten-2">Jetzt bestätigen</v-btn>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "ConfirmMailHint",
  data() {
    return {
      showSnackbar: true
    };
  }
};
</script>

<style scoped>
.confirm-button {
  height: 100% !important;
}
</style>