<template>
  <div>
    <v-row class="mt-8" style="width: 75%; margin: auto">
      <v-col class="col-12 col-sm-6 mb-4" style="align-self: center">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/MN22.svg"></v-img>
      </v-col>
      <v-col class="col-12 col-sm-6 mb-4" style="align-self: center">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/KI22.svg"></v-img>
      </v-col>
      <v-col class="col-12 col-sm-6 mb-4" style="align-self: center">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/WP22.svg"></v-img>
      </v-col>
      <v-col class="col-12 col-sm-6 mb-4" style="align-self: center" v-if="false">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/GM_weiss_SVG.svg"></v-img>
      </v-col>
      <v-col class="col-12 col-sm-6 mb-4" style="align-self: center" v-if="false">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/UB_weiss_SVG.svg"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NetworkLogosVertical"
};
</script>

<style scoped>

.network-logo {
}

.network-logo-container {
}

</style>