<!--
  page gets called with optional prop "lessonIdProp"
  when mounted, lesson data at /lessons gets fetched and value of lessonIdProp is supplied (can be integer or "undefined")
-->

<template>
  <v-main>
    <v-container v-if="!(lesson) || false" class="pa-0 pa-sm-4 mt-md-2 mt-lg-4" fluid>
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-skeleton-loader type="image" class="video-skeleton"></v-skeleton-loader>
        </div>
        <div class="d-none d-lg-block flex-lesson-sidebar-wrapper">
          <v-skeleton-loader type="heading" class="ml-4 mt-6"></v-skeleton-loader>
          <div class="lesson-sidebar-wrapper px-4" style="">
            <v-skeleton-loader type="paragraph" class="mt-5"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph" class="mt-11"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph" class="mt-11"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph" class="mt-11"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph" class="mt-11"></v-skeleton-loader>
          </div>
        </div>
      </div>
      <v-skeleton-loader type="heading" class="mt-8"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph" class="mt-8"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph" class="mt-4"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph" class="mt-4"></v-skeleton-loader>
    </v-container>

    <v-container v-else :class="{'d-none': showFullPageLessonList}" class="pa-0 pa-sm-4 mt2-md-2 mt-lg-4" fluid>
      <div class="d-flex" :class="{'elevation-2': !$vuetify.breakpoint.xs}">
        <div class="flex-video-wrapper">
          <div class="video-wrapper">
            <div class="video-player-loading-hint">
              <div class="d-inline-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    color="grey"
                    class="mb-3"
                ></v-progress-circular>
                <span class="text-subtitle-2 grey--text text--darken-2">
              Videoplayer lädt
            </span>
              </div>
            </div>
            <vimeo-player ref="player" :video-id='lesson["videoId"]'
                          @loaded="playerReady" @timeupdate="timeupdate"></vimeo-player>
          </div>
        </div>
        <div class="d-none d-lg-block flex-lesson-sidebar-wrapper" style="background-color: white">

          <div class="d-flex align-center px-4" style="height: 79px">
            <div class="ml2-4 text-h5 black--text" style2="font-weight: 500">{{ course.name }}</div>
            <v-spacer></v-spacer>
            <ProgressCircle3 :diameter="55" :percentage="course['progress']"
                             :color-prop="course['color']"></ProgressCircle3>
          </div>
          <v-divider></v-divider>
          <v-list class="lesson-sidebar-wrapper pt-0">
            <v-list-item-group :color="course['color'] || 'primary'" v-model="selectedLessonIndex">

              <div v-for="(lessonDto, i) in courseLessons" :key="lessonDto.lesson.id">
                <v-list-item to2="{ path: '/lessons/' + lessonDto.lesson.id }"
                             @click="goToLesson(lessonDto['lesson']['id'], i)"
                             :disabled="!firstLessonFinished && lessonDto.lesson.id > 1"
                             :style="validationInProgress ? 'cursor: progress' : ''">
                  <v-list-item-content class="py-3">
                    <v-list-item-subtitle>
                      <div class="d-flex align-center" style="min-height: 24px">
                        Folge {{ i + 1 }}
                        <v-spacer></v-spacer>
                        <v-chip v-if="lessonDto['lessonProgress'] && lessonDto['lessonProgress']['videoWatched']" label
                                small outlined color="green darken-1" class="ml-2">Angesehen
                        </v-chip>
                        <v-chip v-if="!lessonDto['lessonProgress'] || !lessonDto['lessonProgress']['videoWatched']"
                                label small outlined color="grey darken-2" class="ml-2">Nicht angesehen
                        </v-chip>
                        <v-chip v-else-if="!lessonDto['lessonProgress']['formCompleted']" label small outlined
                                color="red darken-2" class="ml-2">Offene Aufgaben
                        </v-chip>
                        <v-chip v-if="lessonDto['lessonProgress'] && lessonDto['lessonProgress']['formCompleted']" label
                                small outlined color="green darken-1" class="ml-2">Aufgaben erledigt
                        </v-chip>

                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-title v-text="lessonDto.lesson.title" class="mt-0 mb-2 text-h6"></v-list-item-title>
                    <v-list-item-subtitle class="d-flex">
                      <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                      {{ lessonDto.lesson["duration"] | durationClock }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>

            </v-list-item-group>
          </v-list>

        </div>
      </div>

      <div class="d-flex d-lg-none" style="padding-top: 1px">
        <v-btn small class="text-none grow" tile depressed color="grey lighten-3"
               style="margin-right: 0px; margin-left: 1px; height: 35px" @click="showFullPageLessonList = true">
          <v-icon left>mdi-playlist-play</v-icon>
          Folgenübersicht
        </v-btn>
        <v-btn small class="text-none grow d-lg-none" tile depressed color="grey lighten-3"
               style="margin-right: 1px; margin-left: 1px; height: 35px" @click="openSendErrorDialog">
          <v-icon left>mdi-flag-outline</v-icon>
          Problem melden
        </v-btn>
      </div>

      <div class="px-4 py-4 px-sm-0 py-sm-5 pb-md-7" style="background-color2: lightgray; width: 100%">
        <div class="d-flex">
          <div>
            <div class="video-title text-h6 text-sm-h5 text-md-h4" style="width: 100%" v-text="lesson.title"></div>
            <div class="mt-sm-2 mt-md-3">
              <span class="">
                mit <span class="video-author">Roy Spitzner</span>
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>

          <v-btn class="text-none d-none d-lg-block" small color="white" @click="openSendErrorDialog">
            <v-icon left>mdi-flag-outline</v-icon>
            Problem melden
          </v-btn>

        </div>
      </div>

      <div class="card-container pb-4">
        <v-card class="" shaped>
          <!--<v-card-title class="pb-0 px-sm-6 pt-sm-5 text-h5 secondary--text text--lighten-1">
            <v-icon class="mr-2">mdi-lead-pencil</v-icon>
            <span>Aufgaben</span>
          </v-card-title>-->
          <v-card-title class="text-h5 pl-sm-6">
            Aufgaben
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-sm-6 pb-sm-5">
            <Form v-if="lessonId > 0" reference="lesson" :lesson-id="lessonId"
                  alt-text="Zu dieser Folge gibt es keine Aufgaben" :valid-status-callback="updateFormStatus"
                  :advance-button-text="advanceButtonText" :advance-button-loading="validationInProgress"
                  :advance-callback="goToNextLesson"
                  :disable-advance-button-if-not-primary-target="!firstLessonFinished">

            </Form>
            <!-- <FormDecoupled v-if="lessonId > 0" context="lesson"
                  alt-text="Zu dieser Folge gibt es keine Aufgaben">
            </FormDecoupled> -->
          </v-card-text>
        </v-card>
      </div>

      <div class="mt-2">
        <v-card>
          <v-card-text>

            <div class="d-flex align-center justify-center">
              <router-link :to="{name: 'AboutMe'}" class="my-2">
                <v-avatar color="primary" size="64" class="elevation-1">
                  <img src="@/assets/images/roy/head.jpg">
                </v-avatar>
              </router-link>
              <div class="d-flex flex-wrap align-center">
                <div class="ml-3 my-1 black--text text-body-1">Du hast noch offene Fragen? Buch dir einen Termin!</div>
                <v-btn class="ml-3 my-1" color="primary" small
                       href="https://outlook.office365.com/owa/calendar/RoySpitzner@mitNORM.com/bookings/s/zBEAjygQjECnVrPcLHUJWA2"
                       target="_blank">
                  <v-icon left small class="fix-button-icon-size">mdi-open-in-new</v-icon>
                  Termin buchen
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </div>

      <div class="mt-8 mb-16 mx-2 d-flex justify-center">
        <GoogleWidget></GoogleWidget>
      </div>

    </v-container>

    <v-container v-if="lesson" :class="{'d-none': !showFullPageLessonList}">

      <v-btn tile color="white" class="mb-2" elevation="1" block large @click="showFullPageLessonList = false">
        <v-icon left>mdi-close</v-icon>
        Schließen
      </v-btn>

      <v-sheet elevation="1">
        <div class="d-flex align-center px-4" style="height: 79px">
          <div class="ml2-4 text-h5 black--text" style2="font-weight: 500">{{ course.name }}</div>
          <v-spacer></v-spacer>
          <ProgressCircle3 :diameter="55" :percentage="course['progress']"
                           :color-prop="course['color']"></ProgressCircle3>
        </div>
        <v-divider></v-divider>
        <v-list class="">
          <v-list-item-group :color="course['color'] || 'primary'" v-model="selectedLessonIndex">

            <div v-for="(lessonDto, i) in courseLessons" :key="lessonDto.lesson.id">
              <v-list-item to2="{ path: '/lessons/' + lessonDto.lesson.id }"
                           @click="goToLesson(lessonDto['lesson']['id'], i)"
                           :disabled="!firstLessonFinished && lessonDto.lesson.id > 1"
                           :style="validationInProgress ? 'cursor: progress' : ''">
                <v-list-item-content class="py-3">
                  <v-list-item-subtitle>
                    <div class="d-flex align-center" style="min-height: 24px">
                      Folge {{ i + 1 }}
                      <v-spacer></v-spacer>
                      <v-chip v-if="lessonDto['lessonProgress'] && lessonDto['lessonProgress']['videoWatched']" label
                              small outlined color="green darken-1" class="ml-2">Angesehen
                      </v-chip>
                      <v-chip v-if="!lessonDto['lessonProgress'] || !lessonDto['lessonProgress']['videoWatched']"
                              label small outlined color="grey darken-2" class="ml-2">Nicht angesehen
                      </v-chip>
                      <v-chip v-else-if="!lessonDto['lessonProgress']['formCompleted']" label small outlined
                              color="red darken-2" class="ml-2">Offene Aufgaben
                      </v-chip>
                      <v-chip v-if="lessonDto['lessonProgress'] && lessonDto['lessonProgress']['formCompleted']" label
                              small outlined color="green darken-1" class="ml-2">Aufgaben erledigt
                      </v-chip>

                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-title v-text="lessonDto.lesson.title" class="mt-0 mb-2 text-h6"></v-list-item-title>
                  <v-list-item-subtitle class="d-flex">
                    <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                    {{ lessonDto.lesson["duration"] | durationClock }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>

          </v-list-item-group>
        </v-list>
      </v-sheet>

    </v-container>

    <v-dialog v-model="dialogOpen" max-width="600px">
      <v-card>
        <v-card-title>
          Hinweis
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!advancedWarning">
          <div class="mt-4 mb-8 text-body-1 black--text">Du hast noch nicht alle Aufgaben vollständig bearbeitet.</div>
          <div class="d-flex align-baseline">
            <v-btn color="primary" @click="dialogOpen = false">Zurück</v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined @click="advancedWarning = true">Ignorieren</v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <div class="my-6 text-body-1 black--text">
            Schade, dass du in diesem Moment keine Zeit findest die Aufgaben vollständig zu erfüllen.
            Sie sind elementar bei der Umsetzung deines Lebenserfolges.
            <br>Da ich von Herzen überzeugt bin, dass die Aufgaben dir helfen, bekommst du morgen nochmal eine
            Erinnerung.
            <br>Ich wünsche dir weiterhin viel Spaß und Erfolg bei den weiteren Videos.
            <div class="mt-2">
              Dein Roy
            </div>
          </div>
          <div class="d-flex align-baseline">
            <v-btn color="primary" @click="dialogOpen = false">Zurück</v-btn>
            <v-spacer></v-spacer>
            <v-btn outlined @click="advanceLesson">Ok</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reportProblemDialogOpen" max-width="700px">
      <v-card>
        <v-card-title>Problem melden</v-card-title>
        <v-card-text>
          Bitte wähle alle zutreffenden Probleme aus

          <div v-for="(problemGroup, i) in possibleProblems" :key="problemGroup.groupName" class="mt-2">
            <div v-text="problemGroup.groupName + ':'" class="black--text text-body-1"></div>
            <v-chip-group column multiple active-class="primary--text text--darken-2" v-model="selectedProblems[i]">
              <v-chip v-for="(problem, j) in problemGroup.items" :key="problem" outlined class="chip-multiline">
                {{ problem }}
              </v-chip>
            </v-chip-group>
          </div>

          <div class="mt-4 mb-1">Zusätzliche Informationen:</div>
          <v-textarea outlined v-model="errorComment"></v-textarea>

          <div class="d-flex">
            <v-btn color="primary" outlined @click="reportProblemDialogOpen = false">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="errorReportInProgress" :disabled="errorReportCompleted"
                   @click="sendErrorReport">Senden
            </v-btn>
          </div>

          <div v-if="errorReportCompleted" class="d-flex justify-end mt-3">
            <div class="green--text text--darken-1 text-body-1">Feedback übermittelt - Vielen Dank!</div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

  </v-main>
</template>

<script>
import Form from "@/components/Form";
import mitt from "mitt";
import ProgressCircle3 from "@/components/ProgressCircle3";
import Vue from "vue";
import GoogleWidget from "@/components/GoogleWidget";
import FormDecoupled from "@/components/FormDecoupled";

window.mitt = window.mitt || new mitt();

export default {
  components: { FormDecoupled, GoogleWidget, ProgressCircle3, Form },
  props: ["lessonIdProp", "lessonIdUrlParam"],
  data: () => ({
    lessonId: -1,
    lesson: undefined,
    initialSeconds: 0,
    course: undefined,
    courseLessons: [],
    lessonIndex: -1,
    watchedSeconds: new Set(),
    lastWatchedSecond: null,
    formValid: false,
    dialogOpen: false,
    advancedWarning: false,
    selectedLessonIndex: -1,
    nextLessonId: -1,
    validationInProgress: false,
    firstLessonFinished: false,
    showFullPageLessonList: false,
    reportProblemDialogOpen: false,
    possibleProblems: [
      {
        groupName: "Website",
        items: [
          "Website lädt langsam oder gar nicht",
          "Website lässt sich nicht richtig bedienen",
          "Elemente werden fehlerhaft dargestellt",
          "Eingaben werden nicht gespeichert",
          "Website lässt mich keinen Fortschritt machen",
          "Sicherheitsprobleme"
        ]
      },
      {
        groupName: "Videoplayer",
        items: [
          "Videoplayer lädt nicht",
          "Video stockt oder lädt nicht",
          "Video enthält Ton- oder Bildfehler"
        ]
      },
      {
        groupName: "Inhalt",
        items: [
          "Fehler in Rechtschreibung / Grammatik",
          "Videoinhalt ist unverständlich oder verwirrend",
          "Im Video erwähnte Inhalte fehlen auf der Seite"
        ]
      }

    ],
    selectedProblems: [],
    errorComment: "",
    errorReportInProgress: false,
    errorReportCompleted: false
  }),
  computed: {
    advanceButtonText() {
      if (this.lessonIndex === this.courseLessons.length - 1) {
        return "Zur Kursübersicht";
      } else {
        return "Nächste Folge";
      }
    }
  },
  watch: {},
  filters: {
    durationClock: function(seconds) {
      return Math.trunc(seconds / 60) + ":" + String(seconds % 60).padStart(2, "0");
    }
  },
  mounted() {
    this.fetchLessonData(this.lessonIdUrlParam);
  },
  methods: {
    fetchLessonData(lessonIdProp) {
      //this.$store.dispatch("getRequest", ["lessons/" + lessonIdProp, this.fetchLessonDataCallback]);
      this.$store.dispatch("getRequest", ["lessons/" + lessonIdProp, this.fetchLessonPageDataCallback]);
    },
    fetchLessonPageDataCallback(data) {
      // redirect if necessary
      const redirectToLessonId = data["redirectToLessonId"];
      if (redirectToLessonId > 0) {
        this.$router.push("/lessons/" + redirectToLessonId);
        return;
      }
      this.lessonIndex = data["indexInCourse"];
      this.selectedLessonIndex = this.lessonIndex;
      this.lesson = data["course"]["lessons"][this.lessonIndex]["lesson"];
      this.lessonId = this.lesson["id"];
      this.initialSeconds = data["initialSeconds"];
      this.course = data["course"];
      this.courseLessons = data["course"]["lessons"];
      this.firstLessonFinished = data["firstLessonFinished"];
    },
    playerReady() {
      this.$refs.player.player.setCurrentTime(this.initialSeconds);
    },
    timeupdate(event) {
      let second = Math.floor(event["seconds"]);
      this.watchedSeconds.add(second);
      this.lastWatchedSecond = second;
      this.scheduleWatchedSecondsPush(this.pushWatchedSeconds);
    },
    scheduleWatchedSecondsPush: _.throttle((pushMethod) => {
      pushMethod();
    }, 5000),
    pushWatchedSeconds() {
      let seconds = Array.from(this.watchedSeconds);
      this.watchedSeconds = new Set();
      //this.$store.dispatch("putRequest", ["recent-lesson/" + this.lessonId + "/" + this.lastWatchedSecond]);
      if (seconds.length > 0) {
        this.$store.dispatch("putRequest", ["watchtime-tracking/" + this.lessonId, seconds]);
      }
    },
    updateFormStatus(newStatus) {
      this.formValid = newStatus;
    },
    goToLesson(lessonId, index) {
      this.showFullPageLessonList = false;
      // next tick twice is necessary otherwise value reset will not go through
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.selectedLessonIndex = this.lessonIndex;
        });
      });

      // do nothing if same lesson selected
      if (lessonId === this.lessonId) {
        return;
      }

      // if the index of the clicked lesson is smaller than the current one, just go
      if (index < this.lessonIndex) {
        this.$router.push("/lessons/" + lessonId);
        return;
      }

      this.tryToAdvanceLesson(lessonId);

    },
    goToNextLesson() {
      if (this.lessonIndex === this.courseLessons.length - 1) {
        this.tryToAdvanceLesson(-1);
      } else {
        const nextId = this.courseLessons[this.lessonIndex + 1]["lesson"].id;
        this.tryToAdvanceLesson(nextId);
      }
    },
    tryToAdvanceLesson(lessonId) {
      this.nextLessonId = lessonId;
      if (this.formValid) {
        this.advanceLesson();
      } else {
        // validate form
        this.validationInProgress = true;
        this.$store.dispatch("putRequest", ["forms/by-lesson/" + this.lessonId + "/lesson/validate", {}, this.validateFormCallback]);
      }
    },
    validateFormCallback(data) {
      window.mitt.emit("showFormValidationResult", data);
      this.validationInProgress = false;
      if (data.valid) {
        this.advanceLesson();
      } else {
        this.advancedWarning = false;
        this.dialogOpen = true;
      }
    },
    advanceLesson() {
      if (this.nextLessonId === -1) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push("/lessons/" + this.nextLessonId);
      }
    },
    openSendErrorDialog() {
      this.reportProblemDialogOpen = true;
      this.errorReportInProgress = false;
      this.errorReportCompleted = false;
    },
    assembleErrorReportTags() {
      let tags = [];
      for (let i = 0; i < this.selectedProblems.length; i++) {
        const selectedProblemGroup = this.selectedProblems[i];
        for (const problem of selectedProblemGroup) {
          tags.push(this.possibleProblems[i]["items"][problem]);
        }
      }
      return tags.join(", ");
    },
    sendErrorReport() {
      this.errorReportInProgress = true;
      const data = {
        tags: this.assembleErrorReportTags(),
        comment: this.errorComment
      };
      this.$store.dispatch("postRequest", ["error-reports", data, this.sendErrorReportCallback]);
    },
    sendErrorReportCallback() {
      this.errorReportInProgress = false;
      this.errorReportCompleted = true;
      setTimeout(function() {
        this.selectedProblems = [];
        this.errorComment = "";
        this.reportProblemDialogOpen = false;
      }.bind(this), 1500);
    }
  }
};
</script>

<style>

.video-wrapper embed,
.video-wrapper iframe,
.video-wrapper object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: pan-y !important;
}

.video-skeleton .v-skeleton-loader__image {
  height: 0;
  padding-top: 56.25%;
}

.v-slide-group__wrapper {
  touch-action: initial !important;
}

</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    /*max-width: 1185px;*/
    max-width: 1450px;
  }
}

.flex-video-wrapper {
  flex-grow: 1;
}

.flex-lesson-sidebar-wrapper {
  position: relative;
  width: 376px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  touch-action: pan-y !important;
}

.video-wrapper .video-player-loading-hint {
  transform: translate(calc(50% - 52px), min(22vw, 260px));
}

.lesson-sidebar-wrapper {
  position: absolute;
  left: 0;
  top: 80px;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.lesson-sidebar-wrapper-skeleton {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.video-title {
  line-height: 1.2 !important;
}

.video-author {
  text-decoration: underline;
}

.chip-multiline {
  white-space: normal !important;
  height: initial !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

</style>

