<template>
  <div>
    <v-row dense class="mb-8">
      <v-col class="col-12 col-md-5 col-lg-3 d-flex align-center">
        <div v-html="'Beispiel:'" class="mr-2 text-body-1"></div>
        <v-text-field outlined dense hide-details="auto" label="Was" value="allg. Vermögensaufbau" readonly
                      disabled></v-text-field>
      </v-col>
      <v-col class="col-12 col-md-7 col-lg-3">
        <v-text-field outlined dense hide-details="auto" label="Warum" value="Sicherheit, Handlungsfähigkeit" readonly
                      disabled></v-text-field>
      </v-col>
      <v-col class="col-6 col-md-3 col-lg-2">
        <v-select outlined dense hide-details="auto" label="Wann" :items="timespans" value="2" readonly></v-select>
      </v-col>
      <v-col class="col-6 col-md-3 col-lg-2">
        <v-text-field outlined dense hide-details="auto" label="Wieviel" suffix="€" type="number" value="15000" readonly
                      disabled></v-text-field>
      </v-col>
      <v-col class="d-flex align-center">
        <div class="text-body-1 mr-2">=</div>
        <v-text-field outlined dense hide-details="auto" label="Mtl. Sparrate" readonly value="625" suffix="€"
                      disabled></v-text-field>
      </v-col>
      <v-col class="shrink d-flex align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" text icon v-bind="attrs" v-on="on" disabled>
              <v-icon class="d-none">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Ziel löschen</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <div v-for="(goal, index) in financialGoals" class="mb-1">
      <v-row dense>
        <v-col class="col-12 col-md-5 col-lg-3 d-flex align-center">
          <div v-html="'Ziel&nbsp;' + (index + 1) + ':'" class="mr-2 text-body-1"></div>
          <v-text-field outlined dense hide-details="auto" label="Was" v-model="goal['what']"
                        @input="change"></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-7 col-lg-3">
          <v-text-field outlined dense hide-details="auto" label="Warum" v-model="goal['why']"
                        @input="change"></v-text-field>
        </v-col>
        <v-col class="col-6 col-md-3 col-lg-2">
          <v-select outlined dense hide-details="auto" label="Wann" :items="timespans" v-model="goal['when']"
                    @change="change"></v-select>
        </v-col>
        <v-col class="col-6 col-md-3 col-lg-2">
          <v-text-field outlined dense hide-details="auto" label="Wieviel" suffix="€" type="number"
                        v-model="goal['cost']" @input="change"></v-text-field>
        </v-col>
        <v-col class="d-flex align-center">
          <div class="text-body-1 mr-2">=</div>
          <v-text-field outlined dense hide-details="auto" label="Mtl. Sparrate" readonly suffix="€"
                        :value="goal['rate'] | floatToCurrency"></v-text-field>
        </v-col>
        <v-col class="shrink d-flex align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="red" text icon v-bind="attrs" v-on="on" @click="removeOption(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Ziel löschen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider class="my-3 d-lg-none"></v-divider>
    </div>

    <v-row dense class="mb-1">
      <v-col class="col-12 col-md-5 col-lg-3 d-flex align-center">
        <div v-html="'Ziel AV:'" class="mr-2 text-body-1"></div>
        <v-text-field outlined dense hide-details="auto" label="Was" value="Altersvorsorge" readonly></v-text-field>
      </v-col>
      <v-col class="col-12 col-md-7 col-lg-3">
        <v-text-field outlined dense hide-details="auto" label="Warum"
                      v-model="filledOutComp['data']['retirement']['why']" @input="change"></v-text-field>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-4 d-flex align-center justify-md-end">
        Übernimm den Wert aus dem Altersvorsorgerechner
      </v-col>
      <v-col class="d-flex align-center">
        <div class="text-body-1 mr-2">=</div>
        <v-text-field outlined dense hide-details="auto" label="Mtl. Sparrate" suffix="€" type="number"
                      v-model="filledOutComp['data']['retirement']['rate']" @input="change"
                      :error-messages="retirementRateError"></v-text-field>
      </v-col>
      <v-col class="shrink d-flex align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" text icon v-bind="attrs" v-on="on" disabled>
              <v-icon class="d-none">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Ziel löschen</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-divider class="my-3 d-lg-none"></v-divider>

    <v-btn outlined small color="secondary" class="mt-1" @click="addAndSave">
      <v-icon class="fix-button-icon-size" left>mdi-plus</v-icon>
      Ziel hinzufügen
    </v-btn>

    <v-row dense class="mt-3">
      <v-col class="col-12 col-md-6 col-lg-10 d-flex align-center justify-md-end">
        Summe aller Sparraten:
      </v-col>
      <v-col class="d-flex align-center">
        <div class="text-body-1 mr-2">=</div>
        <v-text-field outlined dense hide-details="auto" label="Mtl. Sparrate" suffix="€"
                      :value="sum | floatToCurrency" readonly></v-text-field>
      </v-col>
      <v-col class="shrink d-flex align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" text icon v-bind="attrs" v-on="on" disabled>
              <v-icon class="d-none">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Ziel löschen</span>
        </v-tooltip>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "FinancialGoalsView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {
      timespans: [
        { value: "1", text: "in 1 Jahr" },
        { value: "2", text: "in 2 Jahren" },
        { value: "3", text: "in 3 Jahren" },
        { value: "4", text: "in 4 Jahren" },
        { value: "5", text: "in 5 Jahren" },
        { value: "6", text: "in 6 Jahren" },
        { value: "7", text: "in 7 Jahren" },
        { value: "8", text: "in 8 Jahren" },
        { value: "9", text: "in 9 Jahren" },
        { value: "10", text: "in 10 Jahren" },
        { value: "11", text: "in 11 Jahren" },
        { value: "12", text: "in 12 Jahren" },
        { value: "13", text: "in 13 Jahren" },
        { value: "14", text: "in 14 Jahren" },
        { value: "15", text: "in 15 Jahren" }
      ],
      sum: 0
    };
  },
  computed: {
    financialGoals() {
      return this.filledOutComp["data"]["financialGoals"];
    },
    retirementRateError() {
      if (this.validationResult) {
        if (this.validationResult.status === "EMPTY") {
          return "Bitte ausfüllen";
        }
      }
      return "";
    }
  },
  filters: {
    floatToCurrency: function(value) {
      if (isNaN(value)) {
        return "";
      }
      const rounded = Math.round(value * 100) / 100;
      return (rounded + "").replace(".", ",");
    }
  },
  created() {
    if (!this.filledOutComp["data"]["financialGoals"]) {
      Vue.set(this.filledOutComp["data"], "financialGoals", []);
      this.addFinancialGoal();
      this.addFinancialGoal();
      this.addFinancialGoal();
    }
    if (!this.filledOutComp["data"]["retirement"]) {
      const retirement = {
        what: "_RETIREMENT_",
        why: "",
        rate: "",
        priority: 1000
      };
      Vue.set(this.filledOutComp["data"], "retirement", retirement);
    }
    this.calculateSavingRates();
  },
  methods: {
    calculateSavingRates() {
      let sum = 0;
      for (let financialGoal of this.financialGoals) {
        const when = financialGoal["when"];
        const cost = financialGoal["cost"];
        if (when && cost) {
          const rate = Math.round(cost / (when * 12) * 100) / 100;
          financialGoal["rate"] = rate;
          sum += rate;
        } else {
          financialGoal["rate"] = 0;
        }
      }
      sum += parseFloat(this.filledOutComp["data"]["retirement"]["rate"]);
      this.sum = sum;
    },
    change() {
      this.calculateSavingRates();
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    },
    addFinancialGoal() {
      let highestPriority = 0;
      for (let financialGoal of this.financialGoals) {
        const priority = financialGoal["priority"];
        highestPriority = Math.max(priority, highestPriority);
      }
      const newGoal = {
        what: "",
        when: "",
        why: "",
        cost: "",
        priority: highestPriority + 1
      };
      this.filledOutComp["data"]["financialGoals"].push(newGoal);
    },
    addAndSave() {
      this.addFinancialGoal();
      this.change();
    },
    removeOption(index) {
      this.filledOutComp["data"]["financialGoals"].splice(index, 1);
      this.change();
    }
  }
};
</script>

<style scoped>

</style>