<template>
  <div>
    <v-btn outlined block class="d-sm-none" :disabled="!enableButton" :loading="generationInProgress"
           @click="buttonPressed">
      PDF erzeugen & Herunterladen
    </v-btn>
    <v-btn outlined class="d-none d-sm-inline-flex" :disabled="!enableButton" :loading="generationInProgress"
           @click="buttonPressed">
      PDF erzeugen & Herunterladen
    </v-btn>
    <div v-if="status !== 0" class="mt-1">
      <div v-if="status === -1" class="error--text">
        Fehler beim Erzeugen der Datei
      </div>
      <div v-else-if="status === 1" class="success--text">
        Datei erfolgreich erzeugt
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileGenerationView",
  props: ["component", "filledOutComp", "onChange", "validationResult", "formId", "reference"],
  data: () => {
    return {
      generationInProgress: false,
      status: 0
    };
  },
  computed: {
    templateId() {
      return this.component.data["templateId"];
    },
    title() {
      return this.component.data["title"];
    },
    enableButton() {
      return this.templateId > 0;
    }
  },
  methods: {
    buttonPressed() {
      this.generationInProgress = true;
      const data = {
        templateId: this.templateId,
        title: this.title,
        formId: this.formId,
        reference: this.reference
      };
      this.$store.dispatch("postRequest", ["file-generation/pdf", data, this.generateSuccess, this.generateError]);
    },
    generateError() {
      this.generationInProgress = false;
    },
    generateSuccess(data) {
      const fileName = data["fileName"];
      this.$store.dispatch("downloadFile", ["file-generation/download/" + fileName, this.title + ".pdf", null, this.downloadSuccess, this.downloadError]);
    },
    downloadSuccess() {
      this.generationInProgress = false;
    },
    downloadError() {
      this.generationInProgress = false;
    }
  }
};
</script>

<style scoped>

</style>