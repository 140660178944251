import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js"

import Home from "../views/Home.vue";
import Video from "@/views/Lessons";
import InstaGenerator from "@/views/InstaGenerator";
import LandingPage from "@/views/LandingPage";
import Login from "@/views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage
  },
  {
    path: "/login",
    name: "Login",
    props: true,
    component: Login
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUp.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/lessons",
    name: "Lessons",
    props: true,
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/Lessons.vue")
  },
  {
    path: "/lessons/:lessonIdUrlParam",
    name: "LessonWithId",
    props: true,
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/Lessons.vue")
  },
  {
    path: "/bildgenerator",
    name: "Bildgenerator",
    component: InstaGenerator
  },
  {
    path: "/internal-area",
    name: "InternalArea",
    component: () =>
      import(/* webpackChunkName: "internal-area" */ "../views/InternalArea.vue")
  },
  {
    path: "/internal-area/dial-assistant",
    name: "DialAssistant",
    component: () =>
      import(/* webpackChunkName: "dial-assistant" */ "../views/DialAssistant.vue")
  },
  {
    path: "/internal-area/contact-lists",
    name: "ContactLists",
    component: () =>
      import(/* webpackChunkName: "contact-lists" */ "../views/ContactLists.vue")
  },
  {
    path: "/internal-area/contact-lists/:contactListId",
    name: "ContactList",
    props: true,
    component: () =>
      import(/* webpackChunkName: "contact-list" */ "../views/ContactLists.vue")
  },
  {
    path: "/internal-area/sales",
    name: "Sales",
    props: true,
    component: () =>
      import(/* webpackChunkName: "sales" */ "../views/Sales.vue")
  },
  {
    path: "/internal-area/member-list",
    name: "MemberList",
    props: true,
    component: () =>
      import(/* webpackChunkName: "member-list" */ "../views/MemberList.vue")
  },
  {
    path: "/internal-area/member-view/:userId",
    name: "MemberView",
    props: true,
    component: () =>
      import(/* webpackChunkName: "member-view" */ "../views/MemberView.vue")
  },
  {
    path: "/internal-area/email-template-editor",
    name: "EmailTemplateEditor",
    component: () =>
      import(/* webpackChunkName: "email-template-editor" */ "../views/EmailEditor.vue")
  },
  {
    path: "/internal-area/outgoing-emails",
    name: "OutgoingEmails",
    component: () =>
      import(/* webpackChunkName: "outgoing-emails" */ "../views/OutgoingEmails.vue")
  },
  {
    path: "/internal-area/course-editor",
    name: "CourseEditor",
    component: () =>
      import(/* webpackChunkName: "course-editor" */ "../views/CourseEditor.vue")
  },
  {
    path: "/internal-area/lesson-editor/:lessonId",
    name: "LessonEditor",
    props: true,
    component: () =>
      import(/* webpackChunkName: "lesson-editor" */ "../views/LessonEditor.vue")
  },
  {
    path: "/internal-area/form-editor",
    name: "FormEditor",
    props: true,
    component: () =>
      import(/* webpackChunkName: "form-editor" */ "../views/FormEditor.vue")
  },
  {
    path: "/internal-area/process-chains",
    name: "ProcessChains",
    props: true,
    component: () =>
      import(/* webpackChunkName: "process-chains" */ "../views/ProcessChains.vue")
  },
  {
    path: "/test",
    name: "Test",
    props: true,
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/Test.vue")
  },
  // legacy content
  {
    path: "/literaturempfehlungen",
    name: "Literaturempfehlungen",
    props: true,
    component: () =>
      import(/* webpackChunkName: "literaturempfehlungen" */ "../views/Literaturempfehlungen.vue")
  },
  {
    path: "/downloads",
    name: "Downloads",
    props: true,
    component: () =>
      import(/* webpackChunkName: "downloads" */ "../views/Downloads.vue")
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    props: true,
    component: () =>
      import(/* webpackChunkName: "p-policy" */ "../views/PrivacyPolicy.vue")
  },
  {
    path: "/customer-information",
    name: "CustomerInformation",
    props: true,
    component: () =>
      import(/* webpackChunkName: "customer-information" */ "../views/CustomerInformation.vue")
  },
  {
    path: "/network",
    name: "Network",
    props: true,
    component: () =>
      import(/* webpackChunkName: "network" */ "../views/Network.vue")
  },
  {
    path: "/about-me",
    name: "AboutMe",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about-me" */ "../views/AboutMe.vue")
  },
  {
    path: "/impressum",
    name: "Impressum",
    props: true,
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue")
  },
  {
    path: "/corporate-identity",
    name: "CorporateIdentity",
    props: true,
    component: () =>
      import(/* webpackChunkName: "corporate-identity" */ "../views/CorporateIdentity.vue")
  },
  {
    path: "/personality-test",
    name: "PersonalityTest",
    props: true,
    component: () =>
      import(/* webpackChunkName: "personality-test" */ "../views/PersonalityTest.vue")
  },
  {
    path: "/roy-customers",
    name: "RoyCustomers",
    props: true,
    component: () =>
      import(/* webpackChunkName: "roy-customers" */ "../views/RoyCustomers.vue")
  },
  {
    path: "/jahresupdate",
    name: "Jahresupdate",
    props: true,
    component: () =>
      import(/* webpackChunkName: "jahresupdate" */ "../views/Jahresupdate.vue")
  },
  {
    path: "/expired-link",
    name: "ExpiredLink",
    props: true,
    component: () =>
      import(/* webpackChunkName: "expired-link" */ "../views/ExpiredLink.vue")
  },
  {
    path: "/accept-terms",
    name: "AcceptTerms",
    props: true,
    component: () =>
      import(/* webpackChunkName: "accept-terms" */ "../views/AcceptTerms.vue")
  },
  {
    path: "/confirm-account",
    name: "ConfirmAccount",
    props: true,
    component: () =>
      import(/* webpackChunkName: "confirm-account" */ "../views/ConfirmAccount.vue")
  },
  {
    path: "/unsubscribed",
    name: "Unsubscribed",
    props: true,
    component: () =>
      import(/* webpackChunkName: "unsubscribed" */ "../views/Unsubscribed.vue")
  },
  {
    path: "/r/:code",
    name: "ReferralCode",
    props: true,
    component: () =>
      import(/* webpackChunkName: "referral-code" */ "../views/ReferralCode.vue")
  },
  {
    path: "/xing",
    redirect: to => {
      return { path: '/r/xing' }
    }
  },
  {
    path: "/ai",
    name: "AI",
    props: true,
    component: () =>
      import(/* webpackChunkName: "ai" */ "../views/AiMatchmaking.vue")
  },
  {
    path: "/asdf",
    name: "Asdf",
    props: true,
    component: () =>
      import(/* webpackChunkName: "asdf" */ "../views/Asdf.vue")
  },
  {
    path: "/layout-engine",
    name: "LayoutEngine",
    props: true,
    component: () =>
      import(/* webpackChunkName: "layout-engine" */ "../views/otherstuff/LayoutEngine.vue")
  },
  {
    path: "/coaching",
    name: "Coaching",
    props: true,
    component: () =>
      import(/* webpackChunkName: "coaching" */ "../views/Coaching.vue")
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404.vue")
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

router.beforeEach((to, from, next) => {
  let publicPages = ["LandingPage", "Login", "SignUp", "PrivacyPolicy", "CustomerInformation", "Network", "AboutMe", "Impressum", "PersonalityTest", "404", "ExpiredLink", "ReferralCode", "Unsubscribed", "CorporateIdentity", "Asdf", "LayoutEngine"];
  let notSavedPages = ["LandingPage", "Login", "SignUp", "PrivacyPolicy", "CustomerInformation", "Network", "AboutMe", "Impressum", "PersonalityTest", "404", "ExpiredLink", "AcceptTerms", "ConfirmAccount", "ReferralCode", "Unsubscribed", "CorporateIdentity", "Asdf", "LayoutEngine"];

  let targetName = to.name;

  // save page to open after login
  if (!notSavedPages.includes(targetName)) {
    store.commit("setRouteAfterLogin", to.path);
  }

  // accept access to public pages
  if (publicPages.includes(targetName)) {
    next();
    return;
  }

  // check if user is not logged in
  if (!store.state.userIsLoggedIn) {
    next("/login");
    //return;
  }

  // TODO check for internal access

  next();

});

export default router;
