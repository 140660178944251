<template>
  <div>
    <v-textarea v-if="multiline" outlined rows="4" v-model="data.content" hide-details="auto"
                :label="component['data']['label']"
                @input="change" :success-messages="successMessage"
                :error-messages="errorMessage"></v-textarea>
    <v-text-field v-else outlined v-model="data.content" hide-details="auto" :label="component['data']['label']"
                  @input="change" :success-messages="successMessage"
                  :error-messages="errorMessage" dense></v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextFieldView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    multiline() {
      return this.component.data.multiline;
    },
    data() {
      return this.filledOutComp.data;
    },
    successMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "CORRECT") {
          return "Korrekt";
        }
      }
      return "";
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Inkorrekte Antwort";
        }
        if (this.validationResult.status === "EMPTY") {
          return "Bitte ausfüllen";
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

</style>