<template>
  <div>
    <div class="d-flex align-center justify-center">
      <div class="network-logo-container mr-10" v-if="false">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/UB_weiss_SVG.svg" width="160" contain></v-img>
      </div>
      <div class="network-logo-container mr-10">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/KI22.svg" width="180" contain></v-img>
      </div>
      <div class="network-logo-container mr-10">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/MN22.svg" width="220" contain></v-img>
      </div>
      <div class="network-logo-container mr-10">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/WP22.svg" width="180" contain></v-img>
      </div>
      <div class="network-logo-container" v-if="false">
        <v-img class="network-logo" src="@/assets/images/network_logos/white_svg/GM_weiss_SVG.svg" width="130" contain></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkLogos"
};
</script>

<style scoped>

.network-logo {
}

.network-logo-container {
}

</style>