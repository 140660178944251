<template>
  <div>

    <v-sheet :outlined="error" :rounded="error" :class="error ? 'pa-2 pt-1' : ''" style="border-color: #ff5252 !important">
      <div v-for="(slot, index) in slots">
        <div v-text="slot['label']" class="text-body-1 black--text mb-1" :class="{'mt-3': index > 0}"></div>
        <v-sheet outlined2 color2="grey lighten-4" rounded class="d-flex pa-0" style2="min-height: 82px;">
          <v-chip v-if="filledOutComp['data']['assignments'].hasOwnProperty(index)" label
                  v-text="pairs[filledOutComp['data']['assignments'][index]]['element']"
                  class="multiline-chip" color="primary" outlined
                  @click.stop="openDialog(index)"></v-chip>
          <v-chip v-else label color2="grey darken-2" outlined @click.stop="openDialog(index)"
                  v-text="(is_touch_enabled ? 'Tippen' : 'Klicken') + ' zum Auswählen'"></v-chip>
        </v-sheet>
      </div>
    </v-sheet>
    <div class="error-message mt-2" v-if="error" v-text="errorMessage"></div>

    <v-dialog v-model="dialogOpen" max-width="800px">
      <v-card>
        <v-card-title v-text="dialogHeadline"></v-card-title>
        <v-card-text>
          <div v-text="hintText" class="mb-1"></div>
          <v-sheet outlined2 rounded color2="grey lighten-4" class="pa-0 px2-2 py2-1">
            <v-row dense>
              <v-col v-for="(el, index) in elements" :key="index" class="col-12">
                <v-chip label outlined class="text-body-12 multiline-chip" v-text="el.label" @click="assign(el.index)"
                        :color="usedElements.includes(el.index) ? 'grey' : 'primary'"></v-chip>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "AssignView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {
      pairs: [],
      slots: [],
      elements: [],
      dialogOpen: false,
      dialogHeadlineOld: "",
      currentSlotIndex: -1
    };
  },
  computed: {
    dialogHeadline() {
      return this.currentSlotIndex > -1 ? this.slots[this.currentSlotIndex].label : "";
    },
    is_touch_enabled() {
      return ("ontouchstart" in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0);
    },
    hintText() {
      return (this.is_touch_enabled ? "Tippe" : "Klicke") + " auf ein Element, um es einzufügen.";
    },
    usedElements() {
      if (this.filledOutComp["data"]["assignments"]) {
        return Object.values(this.filledOutComp["data"]["assignments"]);
      } else {
        return [];
      }
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Inkorrekte Zuordnung"
        }
        if (this.validationResult.status === "EMPTY") {
          return "Unvollständige Zuordnung"
        }
        if (this.validationResult.status === "PARTIALLY_FILLED") {
          return "Unvollständige Zuordnung"
        }
      }
      return "";
    },
    error() {
      return this.errorMessage !== "";
    }
  },
  mounted() {
    let pairs = this.component.data["pairs"];
    let slots = [];
    let elements = [];
    for (let i = 0; i < pairs.length; i++) {
      let slot = {
        index: i,
        label: pairs[i].slot
      };
      let element = {
        index: i,
        label: pairs[i].element
      };
      slots.push(slot);
      elements.push(element);
    }
    this.pairs = pairs;
    this.slots = slots;
    this.shuffleArray(elements);
    this.elements = elements;

    if (!this.filledOutComp["data"]["assignments"]) {
      Vue.set(this.filledOutComp["data"], "assignments", {});
    }
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    openDialog(slotIndex) {
      this.currentSlotIndex = slotIndex;
      //this.dialogHeadline = this.slots[slotIndex].label;
      this.dialogOpen = true;
    },
    assign(elementIndex) {
      const assignments = this.filledOutComp["data"]["assignments"];
      // remove element from assignments if already present
      for (const [key, value] of Object.entries(assignments)) {
        if (value === elementIndex) {
          Vue.delete(assignments, key);
        }
      }
      Vue.set(assignments, this.currentSlotIndex, elementIndex);
      this.change();
      this.dialogOpen = false;
    },
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
};
</script>

<style scoped>

.multiline-chip {
  white-space: normal !important;
  height: initial !important;
  min-height: 32px;
  width: 100%;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.error-message {
  font-size: 12px;
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}

</style>