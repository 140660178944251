<template>
  <div>
    <v-radio-group hide-details="auto" class="mt-0" @change="change"
                   v-model="filledOutComp.data.selection" :success-messages="successMessage" :error-messages="errorMessage">
      <v-radio v-for="option in component.data.options" :key="option.text" :label="option.text"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonsView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {}
  },
  computed: {
    successMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "CORRECT") {
          return "Korrekt"
        }
      }
      return "";
    },
    errorMessage() {
      if (this.validationResult) {
        if (this.validationResult.status === "INCORRECT") {
          return "Inkorrekte Antwort"
        }
        if (this.validationResult.status === "EMPTY") {
          return "Bitte eine Option wählen"
        }
      }
      return "";
    }
  },
  methods: {
    change() {
      if (this.validationResult) {
        this.validationResult.status = "";
      }
      this.onChange(this.filledOutComp);
    }
  }
}
</script>

<style scoped>

</style>