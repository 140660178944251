<template>
  <div class="parent">
    <v-img :src="imageSrc" :height="height" class="child-image">
      <div class="fill-height bottom-gradient"></div>
    </v-img>
    <div class="child-overlay" v-html="title"></div>
  </div>
</template>

<script>
export default {
  name: "CurrentEvent",
  props: ["imageName", "title"],
  computed: {
    imageSrc() {
      return require('../assets/images/current_events/centered/' + this.imageName + '.jpg');
    },
    height() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 80
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 100
      }
      return 90;
    }
  }
};
</script>

<style scoped>
.parent {
 position: relative;
}
.child-image {

}
.child-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  line-height: 1.1;
  padding: 3px 3px;
}
.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 80px);
}
</style>