<template>
  <div class="d-flex flex-wrap justify-center align-center">
    <a target="_blank" class="text-decoration-none mx-3"
       href="https://www.google.com/search?client=firefox-b-d&q=roy+spitzner#lrd=0x47a7354f8541872f:0x2634803dbeeb9cc2,1,,,">
      <v-sheet elevation="2">
        <div class="upper-line"></div>
        <div class="d-flex px-3 py-2">
          <v-img src="@/assets/images/external/google.svg" width="50" contain></v-img>
          <div class="ml-2">
            <div class="text-caption small-text">Google Bewertung</div>
            <div class="d-flex align-center my-n1">
              <div class="rating mr-1">{{ rating | formatRating }}</div>
              <v-rating length="5" :value="rating | roundStars" half-increments dense readonly color="#e7711b"></v-rating>
            </div>
            <div class="text-caption small-text">{{ numberOfRatings }} Bewertungen</div>
          </div>
        </div>
      </v-sheet>
    </a>
    <a target="_blank" class="text-decoration-none" href="https://search.google.com/local/writereview?placeid=ChIJL4dBhU81p0cRwpzrvj2ANCY">
      <div class="align-content-center my-4 grey--text text--darken-3">
        Hat dir das Video gefallen?
        <span class="text-decoration-underline">Bewerte uns auf Google!</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "GoogleWidget",
  data() {
    return {
      rating: 4.9,
      numberOfRatings: 23
    };
  },
  filters: {
    formatRating: function(value) {
      return (Math.round(value * 10) / 10).toFixed(1);
    },
    roundStars: function(value) {
      return Math.round(value * 2) / 2
    }
  }
};
</script>

<style scoped>
.upper-line {
  height: 5px;
  background-color: grey; /*#4285F4;*/
}

.rating {
  font-size: 15pt;
  color: #313131;
}

.small-text {
  color: #4f4f4f;
}
</style>