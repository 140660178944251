<template>
  <div class="fill-height">
    <v-card tile class="fill-height" flat :color="$vuetify.theme.themes.light.c_bg_landingpage">
      <v-card-text>
        <div class="d-flex flex-wrap justify-center mt-1">
          <div class="handwriting mr-2 mb-1">Jetzt registrieren!</div>
        </div>

        <div class="subtitle-1 mt-2">Anmelden mit:</div>
        <SocialLogin></SocialLogin>

        <v-divider class="mt-3 mb-2"></v-divider>
        <div class="subtitle-1">Oder mit E-Mail-Adresse:</div>
        <v-row dense>
          <v-col>
            <v-btn to="/login" class="text-none" elevation="1" color="white" style="width: 100%">
              <v-icon left class="fix-button-icon-size">mdi-login</v-icon>
              In Account einloggen
            </v-btn>
          </v-col>
          <v-col>
            <v-btn to="/signup" class="text-none" elevation="1" color="white" style="width: 100%">
              <v-icon left class="fix-button-icon-size">mdi-account-plus-outline</v-icon>
              Neuen Account erstellen
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SocialLoginGoogleButton from "@/components/SocialLoginGoogleButton";
import SocialLoginFacebookButton from "@/components/SocialLoginFacebookButton";
import SocialLoginMicrosoftButton from "@/components/SocialLoginMicrosoftButton";
import SocialLoginAppleButton from "@/components/SocialLoginAppleButton";
import SocialLogin from "@/components/SocialLogin";

export default {
  name: "LandingPageLoginWidget",
  components: { SocialLogin, SocialLoginAppleButton, SocialLoginMicrosoftButton, SocialLoginFacebookButton, SocialLoginGoogleButton }
};
</script>

<style scoped>

.login-with {

}

.handwriting {
  font-family: "Caveat", "Ink Free", cursive;
  color: #1a3636;
  font-size: 20pt;
  font-weight: 500;
}

</style>