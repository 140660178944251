<template>
  <div class="d-flex align-center custom-social-login-container" @click="login">
    <div class="custom-social-login-logo d-flex justify-center">
      <v-img src="@/assets/images/social_login/Google_G_Logo.svg" max-width="18" contain></v-img>
    </div>
    <div v-if="!loginInProgress" class="custom-social-login-text">Google</div>
    <div v-else class="d-flex justify-center flex-grow-1">
      <v-progress-circular indeterminate color="white" size="25" width="3"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";

export default {
  name: "SocialLoginGoogleButton",
  data() {
    return {
      loginInProgress: false
    };
  },
  mounted() {

  },
  methods: {
    login() {
      this.loginInProgress = true;

      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("email");
      firebase.auth().signInWithPopup(provider).then(firebaseResponse => {
        this.$store.dispatch("processFirebaseResponse", [firebaseResponse, this.loginCallback]);
      }).catch(() => {
        this.$store.dispatch("showError", "Anmeldung fehlgeschlagen oder abgebrochen");
        this.loginInProgress = false;
      });

    },
    loginCallback(data) {
      this.loginInProgress = false;
      if (data.success) {
        let routeAfterLogin = this.$store.state.routeAfterLogin;
        if (routeAfterLogin === "") {
          this.$router.push("/home");
        } else {
          this.$router.push(routeAfterLogin);
        }
      }
    }
  }
};
</script>

<style scoped>
.custom-social-login-container {
  background-color: #4285f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  border-radius: 2px;
  cursor: pointer;
}

.custom-social-login-logo {
  width: 40px;
  height: 40px;
  margin: 1px;
  border-radius: 1px;
  background-color: white;
}

.custom-social-login-text {
  font-size: 16px;
  line-height: 42px;
  font-weight: 500;
  letter-spacing: .21px;
  color: white;
  flex-grow: 1;
  text-align: center;
}
</style>