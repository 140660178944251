<template>
  <div>
    <!--
    <v-btn outlined class="text-none" style="overflow:hidden;" @click="linkClicked">
      <v-icon v-if="false" left>mdi-open-in-new</v-icon>
      {{ component.data.name.length === 0 ? 'Link' : component.data.name }}
      <v-icon right>mdi-open-in-new</v-icon>
    </v-btn> -->

    <a class="d-inline-block" target="_blank" :href="component.data.href" :download="download">
      <v-btn outlined class="text-none" style="overflow: hidden;">
        <v-icon class="fix-button-icon-size" left v-if="download">mdi-download</v-icon>
        <v-icon class="fix-button-icon-size" left v-else>mdi-open-in-new</v-icon>
        {{ component.data.name.length === 0 ? "Link" : component.data.name }}
      </v-btn>
    </a>

    <!--
        <div class="d-flex justify-center">
          <a href="/static-downloads/Datenschutzerklärung.pdf" download class="text-decoration-none" style2="width: 100%">
            <v-btn color="primary" class="mb-3 mb-sm-4" outlined2 block>Als PDF herunterladen</v-btn>
          </a>
        </div>
        -->

    <!--
    {{ component.data }}

    <v-btn v-if="component.data['download']" @click="downloadFile">
      download
    </v-btn>

    <a v-else>
      normaler link
    </a>
    -->

  </div>
</template>

<script>
export default {
  name: "LinkView",
  props: ["component", "filledOutComp", "onChange", "validationResult"],
  data: () => {
    return {};
  },
  computed: {
    download() {
      if (this.component.data['download']) {
        return this.component.data["name"];
      }
      return false;
    }
  },
  methods: {
    linkClicked() {
      window.open(this.component.data.href, "_blank").focus();
    },
    downloadFile() {
      const url = this.component.data["href"];
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.component.data["name"]);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style scoped>

</style>